import { useCallback } from "react";

export const useFormData = (
  receivers: any[],
  contractId: string,
  subject: string | undefined
) => {
  const getFormData = useCallback(
    (data: any) => {
      const formData = new FormData();

      const { fileList, email } = data;

      formData.append("contractId", contractId);

      email.forEach((item: string, i: number) => {
        const val = receivers.find((data: any) => data.i.toString() === item);

        val && formData.append(`ReceiverIds[${i}]`, val.id);
      });

      if (fileList[0].originFileObj) {
        formData.append("file", fileList[0].originFileObj);
      } else {
        formData.append("ReportFileItemId", fileList[0].templateId);
      }

      formData.append("subject", subject || data.templateId.label);

      return formData;
    },
    [contractId, receivers, subject]
  );

  return { getFormData };
};
