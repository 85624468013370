import { useCallback } from "react";
import ReportService from "../../../../../services/reportGeneration";
import { useOpenProgressModal } from "../../../../../lib/hooks/useOpenProgressModal";
import { FormInstance } from "antd";
import { useDownloadReport } from "../../../../../lib/hooks/useDownloadReport";

export const useBatchReport = (
  form: FormInstance,
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>,
  onCancelClick: () => void
) => {
  const { download } = useDownloadReport(setIsDownloading);

  const { openModal } = useOpenProgressModal(
    "Generating Batch Report",
    "Generating Batch Report failed",
    (id?: string) => download(ReportService, id)
  );

  const printBatchReport = useCallback(async () => {
    try {
      const res = await form.validateFields();

      setIsDownloading(true);

      const request = {
        dateFrom: res.dates[0].format(),
        dateTo: res.dates[1].format(),
        batchTypes: res.batchTypes,
        paymentOrder: res.paymentOrder,
      };

      const id: string = await ReportService.generateReportByName(
        "BatchReport",
        request
      );

      openModal(id);

      onCancelClick();
    } catch {
      setIsDownloading(false);
    }
  }, [form, onCancelClick, openModal, setIsDownloading]);

  return { printBatchReport };
};
