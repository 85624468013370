import { FormInstance } from "antd";
import { useCallback } from "react";
import { useBatchReport } from "./reports/useBatchReport";
import { useDisbursementsReport } from "./reports/useDisbursementsReport";
import { usePaymentsReport } from "./reports/usePaymentsReport";

type TButtonHandlers = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContentType: React.Dispatch<React.SetStateAction<number>>;
  form: FormInstance;
  contentType: number;
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useButtonHandlers = ({
  setIsOpen,
  setContentType,
  form,
  contentType,
  setIsDownloading,
}: TButtonHandlers) => {
  const onCancelClick = useCallback(() => {
    setIsOpen(false);
    setContentType(0);
    form.resetFields();
  }, [setContentType, setIsOpen, form]);

  const { printBatchReport } = useBatchReport(
    form,
    setIsDownloading,
    onCancelClick
  );

  const { printDisbursementsReport } = useDisbursementsReport(
    form,
    setIsDownloading,
    onCancelClick
  );

  const { printPaymentsReport } = usePaymentsReport(
    form,
    setIsDownloading,
    onCancelClick
  );

  const onPrint = useCallback(async () => {
    try {
      if (contentType === 2) {
        await printBatchReport();
      }

      if (contentType === 3) {
        await printPaymentsReport();
      }

      if (contentType === 4) {
        await printDisbursementsReport();
      }
    } catch {}
  }, [
    contentType,
    printBatchReport,
    printDisbursementsReport,
    printPaymentsReport,
  ]);

  return { onCancelClick, onPrint };
};
