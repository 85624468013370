import { Button, Row } from "antd";

type FormButtonsProps = {
  isDisabled: boolean;
  onPrint: () => void;
  closeModal: () => void;
};

export const FormButtons = ({
  isDisabled,
  onPrint,
  closeModal,
}: FormButtonsProps) => {
  return (
    <Row className="justify-end pt-8">
      <Button
        className="mr-4"
        shape="round"
        type="primary"
        disabled={isDisabled}
        loading={isDisabled}
        onClick={onPrint}
      >
        Print
      </Button>
      <Button shape="round" disabled={isDisabled} onClick={closeModal}>
        Cancel
      </Button>
    </Row>
  );
};
