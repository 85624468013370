import { useCallback, useEffect, useState } from "react";
import CustomerBankAccountsService from "../../../../../../../../../services/customers/bankAccounts";
import { usePrevious } from "../../../../../../../../../lib/hooks/previous";

type TGetBankAccountsProps = {
  customerIds: any;
};

export type TBankAccount = {
  bankAccountNumber: string;
  bankTransitRouting: string;
  customerId: string;
  id: string;
  isPrimary: boolean;
};

export const useGetBankAccounts = ({ customerIds }: TGetBankAccountsProps) => {
  const [isDropdownDisabled, setIsDropdownDisabled] = useState<boolean>(false);

  const [primaryBankAccount, setPrimaryBankAccount] =
    useState<TBankAccount | null>(null);

  const [secBankAccount, setSecBankAccount] = useState<TBankAccount | null>(
    null
  );

  const prevIds = usePrevious(customerIds);

  const fetchBankAccount = useCallback(() => {
    if (
      Object.keys(customerIds).length !== 0 &&
      (customerIds.payeeId || customerIds.alternativePayeeId)
    ) {
      const request = customerIds.alternativePayeeId
        ? customerIds.alternativePayeeId
        : customerIds.payeeId;

      setIsDropdownDisabled(true);
      setPrimaryBankAccount(null);
      setSecBankAccount(null);

      CustomerBankAccountsService.fetchAll(request)
        .then((res: TBankAccount[]) => {
          if (res.length !== 0) {
            res.forEach((bankAccount: TBankAccount) => {
              bankAccount.isPrimary
                ? setPrimaryBankAccount(bankAccount)
                : setSecBankAccount(bankAccount);

              setIsDropdownDisabled(false);
            });
          }
        })
        .catch(() => setIsDropdownDisabled(false))
        .finally(() => setIsDropdownDisabled(false));
    }
  }, [customerIds]);

  useEffect(() => {
    if (
      prevIds &&
      (prevIds.payeeId !== customerIds.payeeId ||
        prevIds.alternativePayeeId !== customerIds.alternativePayeeId)
    ) {
      fetchBankAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBankAccount]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchBankAccount(), []);

  return { isDropdownDisabled, primaryBankAccount, secBankAccount };
};
