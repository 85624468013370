import { useEffect, useState } from "react";
import { IOption } from "../../../../../types/Option";
import TicklersTypeService from "../../../../../services/administration/ticklers";
import { ITicklerTypeExtended } from "../../../../../types/Tickler";

const request = {
  pageSize: 100000,
  pageNumber: 1,
};

export const useFetchTicklerTypes = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [options, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    TicklersTypeService.fetchAll(request)
      .then((res: ITicklerTypeExtended[]) => {
        const result: IOption[] = res.map((record: ITicklerTypeExtended) => ({
          label: record.typeName,
          value: record.id,
        }));

        result.unshift({
          label: "All",
          value: "All",
        });

        setOptions(result);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return { isLoading, options };
};
