import { Button, Row } from "antd";
import { useCallback } from "react";

type FormButtonsProps = {
  isLoading: boolean;
  close: (value: boolean) => void;
  onPrint: () => void;
};

export const FormButtons = ({
  isLoading,
  close,
  onPrint,
}: FormButtonsProps) => {
  const onClose = useCallback(() => close(false), [close]);

  return (
    <Row className="w-100 justify-end pt-5 pb-1">
      <Button
        type="primary"
        shape="round"
        disabled={isLoading}
        loading={isLoading}
        onClick={onPrint}
      >
        Print
      </Button>
      <Button
        shape="round"
        className="ml-2"
        disabled={isLoading}
        onClick={onClose}
      >
        Cancel
      </Button>
    </Row>
  );
};
