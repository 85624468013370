import { Form, FormInstance, Row } from "antd";
import { DisbursementsMethodPart } from "./components/DisbursementMethodPart";
import { StatusPart } from "./components/StatusPart";
import { MainPart } from "./components/MainPart";
import { useGetInitialValues } from "./hooks/useGetInitialValues";
import { Loader } from "../../../../../../../../components/Loader";
import { FeeOverridePart } from "./components/FeeOverridePart";
import { useButtonHandlers } from "./hooks/useButtonHandlers";
import { useGetInpurSearchIds } from "../../../../../../../Accounts/components/Filters/hooks/getInputSearchIds";
import { useMemo, useState } from "react";
import { FormButtons } from "./components/FormButtons";
import { TRecord } from "./types";

type TPayeeFormProps = {
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  };
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contractId: string;
  setRecord: React.Dispatch<React.SetStateAction<TRecord>>;
  record: TRecord;
  isPassthru: boolean;
};

export const PayeeForm = ({
  reloadData,
  setIsOpen,
  payeesNumber,
  contractId,
  setRecord,
  record,
  isPassthru,
}: TPayeeFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [disbursementMethodState, setDisbursementMethodState] = useState({
    isEscrowAcc: false,
    isACHMethod: false,
  });

  const { onCustomerFound, hasPayeeId, customerIds, hasAlternativePayeeId } =
    useGetInpurSearchIds();

  const { onCancelClick, onDeleteBtnClick, onSaveBtnClick, isFormDisabled } =
    useButtonHandlers({ reloadData, setIsOpen, setRecord, form });

  const { isLoading } = useGetInitialValues(
    record.id,
    form,
    setDisbursementMethodState
  );

  const payeeFormClassName = useMemo(() => {
    return `payee-disbursements-form-wrapper
      ${isLoading && "h-100"}
      w-100
      pb-16
      position-relative
      d-flex
      justify-center
      align-center`;
  }, [isLoading]);

  return (
    <Row className={payeeFormClassName}>
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <>
          <Form
            form={form}
            requiredMark={false}
            layout="vertical"
            className="w-100 pt-8 px-6 mb-4 payee-disbursements-form"
            disabled={isFormDisabled}
          >
            <MainPart
              form={form}
              payeesNumber={payeesNumber}
              onCustomerFound={onCustomerFound}
              hasPayeeId={hasPayeeId}
              hasAlternativePayeeId={hasAlternativePayeeId}
              isFormDisabled={isFormDisabled}
              isPassthru={isPassthru}
            />
            <DisbursementsMethodPart
              form={form}
              customerIds={customerIds}
              contractId={contractId}
              disbursementMethodState={disbursementMethodState}
              isFormDisabled={isFormDisabled}
            />
            <StatusPart />
            <FeeOverridePart form={form} isFormDisabled={isFormDisabled} />
          </Form>
          <FormButtons
            onSaveClick={onSaveBtnClick}
            onCancelClick={onCancelClick}
            onDeleteClick={onDeleteBtnClick}
            isFormDisabled={isFormDisabled}
            customerIds={customerIds}
            record={record}
          />
        </>
      )}
    </Row>
  );
};
