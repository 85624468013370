import { useCallback, useState } from "react";
import ReportService from "../../../../../../../../../../../services/reportGeneration";
import { useDownloadReport } from "../../../../../../../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../../../../../../../lib/hooks/useOpenProgressModal";

export const usePrintActivity = (contractId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { download } = useDownloadReport(setIsLoading);

  const { openModal } = useOpenProgressModal(
    "Generating Impound Activity Report",
    "Generating Impound Activity Report failed",
    (id?: string) => download(ReportService, id)
  );

  const print = useCallback(
    async (type: 0 | 1 | 2 | undefined) => {
      try {
        setIsLoading(true);

        const request: any = {
          contractId,
          type,
        };

        const id: string = await ReportService.generateReportByName(
          "ContractImpoundActivityReport",
          request
        );

        openModal(id);

        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    },
    [contractId, openModal]
  );

  return { print, isLoading };
};
