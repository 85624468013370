import { FormInstance } from "antd";
import { useCallback, useEffect } from "react";
import ReportService from "../../../../../../../../../services/reportGeneration";
import BackGroundService from "../../../../../../../../../services/background";
import { DOCX_TYPE } from "../../../../../../../../../lib/constants";
import { useAttachmentTools } from "../../../../../../../../../lib/hooks/useAttachmentTools";

export const useGenerateAttachment = (
  form: FormInstance,
  quoteId: string | null,
  setGlobalIsLoading: (value: boolean) => void
) => {
  const { setError, onFail } = useAttachmentTools(form, setGlobalIsLoading);

  const generate = useCallback(async () => {
    if (quoteId) {
      setError([]);

      const reportId: string = await Promise.resolve(
        ReportService.generateReportByName("PayoffQuoteReport", { id: quoteId })
      );

      const interval = setInterval(() => {
        BackGroundService.getProgress(reportId)
          .then((res: any) => {
            const { status, result } = res;

            if (status === 4) {
              if (result.succesfulCount === 1) {
                clearInterval(interval);

                setGlobalIsLoading(false);

                const value = {
                  templateId: result.additionalData.ResultFileItemId,
                  name: result.additionalData.ResultFileName,
                  type: DOCX_TYPE,
                  status: "done",
                  size: result.additionalData.ResultFileSizeBytes,
                  taskId: reportId,
                  id: reportId,
                };

                form.setFieldValue("fileList", [value]);
              } else {
                setGlobalIsLoading(false);

                clearInterval(interval);

                setError(["Generation failed"]);
              }
            }

            status === 6 && onFail(interval);
          })
          .catch(() => onFail(interval));
      }, 1000);
    }
  }, [quoteId, setError, setGlobalIsLoading, onFail, form]);

  useEffect(() => {
    generate();
  }, [generate]);
};
