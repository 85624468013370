import { useCallback } from "react";
import { useDefaultDeleteModal } from "../../../../../../../lib/hooks/defaultDeleteModal";
import { deletePaymentHistoryRecord } from "../../../../../../../acions/accounts/paymentHistory";

export const useDelete = (reloadData: () => void) => {
  const { openDeleteModal } = useDefaultDeleteModal(
    deletePaymentHistoryRecord,
    reloadData
  );

  const onDeleteBtnClick = useCallback(
    (id: string) => openDeleteModal(id, "Delete Payment History"),
    [openDeleteModal]
  );
  return { onDeleteBtnClick };
};
