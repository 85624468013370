import { useCallback } from "react";
import {
  transformToFloat,
  transformToFormatted,
} from "../../../../../../../lib/transformToFloat";

export const useTransformAmounts = (isRequest: boolean) => {
  const transform = useCallback(
    (data: any) => {
      [
        "beginningPrincipal",
        "principalAndInterestAmount",
        "balancePrincipal",
        "balanceUnpaidInterest",
        "balanceUnpaidLateCharges",
        "balancePartialPayments",
        "balanceImpound",
        "regularOverridenAmount",
        "regularAmountPassthru",
      ].forEach((prop: string) => {
        data[prop] = isRequest
          ? transformToFloat(data[prop])
          : transformToFormatted(data[prop]);
      });
    },
    [isRequest]
  );

  return { transform };
};
