import { useMemo } from "react";
import {
  BANK_ACCOUNT_NUMBER_MIN_MAX,
  PAYEE_DISB_DESCRIPTION_MAX,
  WRONG_FORMAT_BANK_TRANSIT_ROUTING,
} from "../../../../../../../../../../../lib/constants";
import { Rule } from "antd/es/form";

export const useRules = () => {
  const bankAccountNumberRule: Rule[] = useMemo(
    () => [
      {
        min: 6,
        message: BANK_ACCOUNT_NUMBER_MIN_MAX,
        validateTrigger: "onSubmit",
      },
      {
        max: 17,
        message: BANK_ACCOUNT_NUMBER_MIN_MAX,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  const bankTransitNumberRule: Rule[] = useMemo(
    () => [
      {
        min: 9,
        message: WRONG_FORMAT_BANK_TRANSIT_ROUTING,
        validateTrigger: "onSubmit",
      },
      {
        max: 9,
        message: WRONG_FORMAT_BANK_TRANSIT_ROUTING,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  const descriptionRule: Rule[] = useMemo(
    () => [
      {
        max: 60,
        message: PAYEE_DISB_DESCRIPTION_MAX,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  return { bankAccountNumberRule, bankTransitNumberRule, descriptionRule };
};
