import { Form, FormInstance, Row } from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { FloatNumberInput } from "../../../../../../../../../components/FloatNumberInput";
import { getFormattedCurrency } from "../../../../../../../../../lib/currencyFormatter";
import { usePassthruFormItem } from "../../../hooks/usePassthruFormItem";
import { transformToFloat } from "../../../../../../../../../lib/transformToFloat";

type RegularAmountProps = {
  data: any;
  form: FormInstance;
};

export const RegularAmount = ({ data, form }: RegularAmountProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const { isPassthru } = usePassthruFormItem(form);

  const passthruAmount = Form.useWatch("regularAmountPassthru", { form });

  const collapse = useCallback(
    () => setIsCollapsed(!isCollapsed),
    [isCollapsed]
  );

  const collapseIcon: ReactNode = useMemo(
    () =>
      isCollapsed ? (
        <RightOutlined
          className="text-md title logo-cursor"
          onClick={collapse}
        />
      ) : (
        <DownOutlined
          className="text-md title logo-cursor"
          onClick={collapse}
        />
      ),
    [collapse, isCollapsed]
  );

  const itemsMap: any = useMemo(
    () => ({
      regularAmountPrincipalAndInterest: "Principal & Interest",
      regularAmountFee: "Buyer Fee",
      regularAmountImpound: "Impound",
      regularAmountPassthru: "Passthru",
    }),
    []
  );

  const summary: number = useMemo(() => {
    const {
      regularAmountPrincipalAndInterest,
      regularAmountFee,
      regularAmountImpound,
      regularAmountPassthru,
    } = data;

    let sum: number = (regularAmountFee || 0) + (regularAmountImpound || 0);

    sum += isPassthru
      ? transformToFloat(passthruAmount)
      : (regularAmountPrincipalAndInterest || 0) +
        transformToFloat(regularAmountPassthru || 0);

    return sum;
  }, [data, passthruAmount, isPassthru]);

  const paymentItems: ReactNode = useMemo(() => {
    const values = Object.keys(itemsMap)
      .filter((item: string) => item !== "regularAmountPrincipalAndInterest")
      .map((item: string) => ({
        label: itemsMap[item],
        value: data[item],
      }));

    if (!isPassthru) {
      values.unshift({
        label: "Principal & Interest",
        value: data.regularAmountPrincipalAndInterest,
      });
    } else {
      values[values.length - 1].value = transformToFloat(passthruAmount);
    }

    return values.map((item: any, i: number) => (
      <Row
        className="justify-space-between w-100 payment-row payment-row_item"
        key={i}
      >
        <span className="text-md title">{item.label}</span>
        <span>{getFormattedCurrency(item.value || 0)}</span>
      </Row>
    ));
  }, [itemsMap, isPassthru, data, passthruAmount]);

  return (
    <Row>
      <div className="w-100 mb-4 divider" />
      <Row className="justify-space-between w-100">
        <Row>
          {collapseIcon}
          <span className="mx-2 text-md">Regular Amount</span>
        </Row>
        <Row>{getFormattedCurrency(summary || 0)}</Row>
      </Row>
      <Row className="justify-space-between w-100 payment-row_item align-center my-1">
        <Row>
          <span className="text-md title">Overridden Amount</span>
        </Row>
        <Row>
          <FloatNumberInput
            name={"regularOverridenAmount"}
            form={form}
            label=""
            className="mb-0 balance__form-item"
            includeThousandsSeparator
          />
        </Row>
      </Row>
      {!isCollapsed && <Row>{paymentItems}</Row>}
    </Row>
  );
};
