import { FormInstance } from "antd";
import { FloatNumberInput } from "../../../../../../../components/FloatNumberInput";
import { useCallback, useMemo } from "react";

type ToBalanceInputProps = {
  form: FormInstance;
  name: string;
  calculate: (toUpdate?: { name: string; value: any }) => void;
  getDisabled: (names: string[]) => boolean;
};

export const ToBalanceInput = ({
  form,
  name,
  calculate,
  getDisabled,
}: ToBalanceInputProps) => {
  const disabled: boolean = useMemo(
    () => getDisabled(["interest", "principal"]),
    [getDisabled]
  );

  const inputName: string[] = useMemo(
    () => ["breakdown", `${name}ToBalance`],
    [name]
  );

  const onCalculate = useCallback(
    () => calculate({ name: `${name}.ToBalance`, value: true }),
    [calculate, name]
  );

  return (
    <FloatNumberInput
      name={inputName}
      label=""
      form={form}
      className="totals-input to-balance-input mb-0"
      allowNegative
      onPressEnter={onCalculate}
      disabled={disabled}
      includeThousandsSeparator
    />
  );
};
