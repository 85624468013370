import { Button, Dropdown, MenuProps, Row } from "antd";
import { useCallback, useMemo, useState, MouseEvent } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useChecksReport } from "../hooks/useChecksReport";
import { useReceiptReport } from "../hooks/useRecieptReport";

type TPrintDropdownProps = {
  batchId: string;
  isDisabled: boolean;
  reloadData: () => void;
};

export const PrintDropdown = ({
  batchId,
  isDisabled,
  reloadData,
}: TPrintDropdownProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { printChecksReport } = useChecksReport(
    batchId,
    reloadData,
    setIsDownloading
  );

  const { print: printReceipts } = useReceiptReport(batchId, setIsDownloading);

  const onPrintReceipts = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const id: string = e.currentTarget.id;

      printReceipts(id === "2");
    },
    [printReceipts]
  );

  const items: MenuProps["items"] = useMemo(
    () => [
      { key: 1, label: <Row onClick={printChecksReport}>Checks</Row> },
      {
        key: 2,
        label: (
          <Row id="1" onClick={onPrintReceipts}>
            Deposit Receipts
          </Row>
        ),
      },
      {
        key: 3,
        label: (
          <Row id="2" onClick={onPrintReceipts}>
            Deposit Receipts to Escrow Account
          </Row>
        ),
      },
    ],
    [printChecksReport, onPrintReceipts]
  );

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      disabled={isDisabled || isDownloading}
    >
      <Button shape="round">
        Print <DownOutlined />
      </Button>
    </Dropdown>
  );
};
