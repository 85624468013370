import { DatePicker, Form, FormInstance, Input, Row } from "antd";
import { TFormProps } from "../../../../../../../../lib/hooks/defaultFormSidebar/types";
import { BuyerAmounts } from "./BuyerAmounts";
import { Fees } from "./Fees";
import { DATE_FORMAT } from "../../../../../../../../lib/constants";
import { Totals } from "./Totals";
import { ReceiverInfo } from "./ReceiverInfo";
import { FormButtons } from "./FooterButtons";
import { useGetInitialData } from "./hooks/useGetInitialData";
import { useButtonHandlers } from "../../hooks/useButtonHandlers";
import { useFetchCustomerData } from "./hooks/useFetchCustomerData";
import { getFormattedCurrency } from "../../../../../../../../lib/currencyFormatter";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useState } from "react";

const { TextArea } = Input;

type TPayoffFormProps = TFormProps & {
  primaryBuyerId: string;
  primarySellerId: string;
  setIdToPrint: React.Dispatch<React.SetStateAction<string | null>>;
};

export const PayoffForm = ({
  id,
  setId,
  reloadData,
  setIsOpen,
  parentId,
  primaryBuyerId,
  primarySellerId,
  setIdToPrint,
}: TPayoffFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const { onCancelBtnClick, onDeleteBtnClick, onSaveBtnClick, onSaveAndPrint } =
    useButtonHandlers({
      setIsOpen,
      setId,
      reloadData,
      parentId,
      form,
      setIsDisabled,
      setIdToPrint,
    });

  const { fetchCreationData, additionalInfo } = useGetInitialData(
    id,
    form,
    setIsDisabled,
    parentId || ""
  );

  const onDateChange = useCallback(
    (date: Dayjs | null): void => {
      date && fetchCreationData(dayjs(date).format(), true);
    },
    [fetchCreationData]
  );

  const { isLoading: isRecieverDataLoading, data: customersData } =
    useFetchCustomerData(primaryBuyerId, primarySellerId);

  return (
    <>
      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        className="pa-6 w-100 payoff-form"
        disabled={isDisabled}
      >
        <Row className="d-flex align-center">
          <Form.Item
            label="Payoff Date"
            name="date"
            rules={[
              { type: "date", required: true, validateTrigger: "onSubmit" },
            ]}
          >
            <DatePicker format={DATE_FORMAT} onChange={onDateChange} />
          </Form.Item>
          <p className="ma-0 pl-4">
            <span className="text-md w-500">
              {additionalInfo.daysOfInterest}
            </span>{" "}
            day
            {additionalInfo.daysOfInterest === 1 ? "" : "s"} of interest.{" "}
            <span className="text-md w-500">
              {getFormattedCurrency(additionalInfo.interestPerDay, 6)}
            </span>{" "}
            interest per day.
          </p>
        </Row>
        <Row className="w-100 payoff-form-amount-container pb-6">
          <BuyerAmounts form={form} isFormDisabled={isDisabled} />
          <Fees form={form} isFormDisabled={isDisabled} />
          <Form.Item
            label="Comment"
            name="comment"
            className="w-100 align-self-end mb-0"
            rules={[{ max: 1000, validateTrigger: "onSubmit" }]}
          >
            <TextArea
              placeholder="Will be displayed on the quote"
              rows={4}
              className="payoff-form-textarea"
            />
          </Form.Item>
          <Totals form={form} isFormDisabled={isDisabled} />
        </Row>
        <ReceiverInfo
          form={form}
          isFormDisabled={isDisabled || isRecieverDataLoading}
          customersData={customersData}
          id={id}
        />
      </Form>
      <FormButtons
        currentId={id}
        onCancelClick={onCancelBtnClick}
        onDeleteClick={onDeleteBtnClick}
        onSaveClick={onSaveBtnClick}
        isFormDisabled={isDisabled}
        onSaveAndPrint={onSaveAndPrint}
      />
    </>
  );
};
