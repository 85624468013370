import { Form, FormInstance } from "antd";
import { CustomModal } from "../../../../../../../../lib/CustomModal";
import { useCallback, useMemo } from "react";
import { FormContainer } from "./components/FormContainer";
import { IPrimaryData } from "../../../HeaderActions/types/PrimaryData";

type PrintModalProps = {
  contractId: string;
  quoteId: string | null;
  primaryData: IPrimaryData;
  close: () => void;
};

export const PrintModal = ({
  contractId,
  quoteId,
  primaryData,
  close,
}: PrintModalProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const closeModal = useCallback(() => {
    close();
    form.resetFields();
  }, [close, form]);

  const shouldOpen: boolean = useMemo(() => !!quoteId, [quoteId]);

  return (
    <CustomModal
      isModalOpen={shouldOpen}
      setIsModalOpen={closeModal}
      width={600}
      titleText="Print Letter: Payoff Quote"
      headerClass="processing-print-modal--header"
      modalClass="letter-modal"
      destroyOnClose
    >
      <FormContainer
        contractId={contractId}
        form={form}
        primaryData={primaryData}
        quoteId={quoteId}
        close={closeModal}
      />
    </CustomModal>
  );
};
