import dayjs from "dayjs";
import { useCallback, useState } from "react";
import ReportService from "../../../../../services/reportGeneration";
import { useDownloadReport } from "../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../lib/hooks/useOpenProgressModal";

export const useTotalsReport = (request: { from: string; to: string }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { download } = useDownloadReport(setIsLoading);

  const { openModal } = useOpenProgressModal(
    "Generating Totals Report",
    "Generating Totals Report failed",
    (id?: string) => download(ReportService, id)
  );

  const printTotalsReport = useCallback(async () => {
    try {
      setIsLoading(true);

      const req = {
        dateFrom: dayjs(request.from).format(),
        dateTo: dayjs(request.to).format(),
      };

      const id: string = await ReportService.generateReportByName(
        "TotalsReport",
        req
      );

      openModal(id);
    } catch {
      setIsLoading(false);
    }
  }, [request, openModal]);

  return { isLoading, printTotalsReport };
};
