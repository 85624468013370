import { Button, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import { useChecksReport } from "../hooks/useChecksReport";

type TChecksModeProps = {
  batchId: string;
  selectedDisbursementIds: React.Key[];
  setSelectedDisbursementIds: React.Dispatch<React.SetStateAction<React.Key[]>>;
  setIsChecksModeOn: React.Dispatch<React.SetStateAction<boolean>>;
  onMethodChange: (value: number) => void;
  onAssignClick: () => void;
  reloadData: () => void;
};

export const ChecksModeHeader = ({
  batchId,
  selectedDisbursementIds,
  setSelectedDisbursementIds,
  setIsChecksModeOn,
  onMethodChange,
  onAssignClick,
  reloadData,
}: TChecksModeProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { printChecksReport } = useChecksReport(
    batchId,
    reloadData,
    setIsDownloading,
    selectedDisbursementIds
  );

  const onCancel = useCallback(() => {
    setIsChecksModeOn(false);
    setSelectedDisbursementIds([]);
    onMethodChange(0);
  }, [onMethodChange, setIsChecksModeOn, setSelectedDisbursementIds]);

  return (
    <Row className="w-100 d-flex justify-space-between check-mode">
      <Row className="d-flex align-center">
        <h5 className="h h5 mr-4 ant-table-title">Checks</h5>
        {selectedDisbursementIds.length !== 0 ? (
          <p className="ma-0 additional-info">
            Default sorting order will be used for the actions
          </p>
        ) : (
          <p className="ma-0 additional-info">Select a Check</p>
        )}
      </Row>
      <Row>
        {selectedDisbursementIds.length !== 0 && (
          <Row className="d-flex align-center pr-6 additional-actions">
            <p className="ma-0">
              {selectedDisbursementIds.length} Check Selected
            </p>
            <Button shape="round" className="mx-4" onClick={onAssignClick}>
              Assign #
            </Button>
            <Button
              shape="round"
              disabled={isDownloading}
              onClick={printChecksReport}
            >
              Print
            </Button>
          </Row>
        )}
        <Button
          shape="circle"
          icon={<CloseOutlined />}
          onClick={onCancel}
          className="ml-6"
        />
      </Row>
    </Row>
  );
};
