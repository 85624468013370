import { Button, Row } from "antd";
import "./style.scss";
import { ReactNode } from "react";

type TBtnsProps = {
  currentId: string | null;
  onSaveClick: (
    isNew: boolean,
    currentId: string | null,
    customerIds?: any
  ) => Promise<void>;
  onCancelClick: () => void;
  onDeleteClick: (id: string) => void;
  isFormDisabled: boolean;
  customerIds?: any;
  children?: ReactNode;
  isAlternate?: boolean;
  parentId?: string;
};

export const FormButtons = ({
  currentId,
  onSaveClick,
  onCancelClick,
  onDeleteClick,
  isFormDisabled,
  customerIds,
  children,
}: TBtnsProps) => {
  return (
    <Row className="default-form-buttons w-100 pa-6 divider d-flex justify-space-between position-absolute">
      <div className="d-flex default-form-buttons-main">
        <Button
          shape="round"
          type="primary"
          disabled={isFormDisabled}
          loading={isFormDisabled}
          onClick={() => onSaveClick(false, currentId, customerIds)}
        >
          {currentId !== null ? "Save" : "Add"}
        </Button>
        {currentId === null && (
          <Button
            shape="round"
            disabled={isFormDisabled}
            loading={isFormDisabled}
            onClick={() => onSaveClick(true, currentId, customerIds)}
          >
            Add & New
          </Button>
        )}
        <Button shape="round" disabled={isFormDisabled} onClick={onCancelClick}>
          Cancel
        </Button>
      </div>
      <div>
        {currentId !== null && children}
        {currentId !== null && (
          <Button
            type="link"
            disabled={isFormDisabled}
            onClick={() => onDeleteClick(currentId)}
          >
            Delete
          </Button>
        )}
      </div>
    </Row>
  );
};
