import { Form, FormInstance, Row } from "antd";
import { Loader } from "../../../../HeaderActions/components/EmailsModal/components/Loader";
import { ReceiverItem } from "../../../../../../../../../components/LetterModal/components/RecieverItem";
import { useFetchPrimaryData } from "../../../../HeaderActions/components/EmailsModal/hooks/useFetchPrimaryData";
import { useCallback, useState } from "react";
import { IPrimaryData } from "../../../../HeaderActions/types/PrimaryData";
import { Letter } from "../../../../../../../../../components/LetterModal/components/Letter";
import { useGenerateAttachment } from "../hooks/useGenerateAttachment";
import { FooterButtons } from "../../../../../../../../../components/LetterModal/components/FooterButtons";
import { useSave } from "../../../../../../../../../components/LetterModal/hooks/useSave";

type FormContainerProps = {
  contractId: string;
  form: FormInstance;
  primaryData: IPrimaryData;
  quoteId: string | null;
  close: () => void;
};

export const FormContainer = ({
  contractId,
  form,
  primaryData,
  quoteId,
  close,
}: FormContainerProps) => {
  const [sendToData, setSendToData] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isTemplateGenerating, setIsTemplateGenerating] =
    useState<boolean>(true);

  const { formattedData: initalData } = useFetchPrimaryData(
    primaryData,
    setIsLoading
  );

  const { isSaving, onSave, setIsSaving } = useSave(
    contractId,
    form,
    sendToData,
    close,
    "Payoff Quote"
  );

  const setGlobalIsLoading = useCallback(
    (value: boolean) => {
      setIsTemplateGenerating(value);
      setIsSaving(value);
    },
    [setIsSaving, setIsTemplateGenerating]
  );

  useGenerateAttachment(form, quoteId, setGlobalIsLoading);

  return (
    <Row className="d-block position-relative">
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        className={`position-relative ${isTemplateGenerating ? "blured" : ""}`}
        disabled={isSaving}
      >
        {isTemplateGenerating && (
          <Row className="w-100 justify-center">
            <Loader />
          </Row>
        )}

        <ReceiverItem
          form={form}
          initalData={initalData}
          optionsData={sendToData}
          setOptionsData={setSendToData}
          isLoading={isLoading}
          disabled={false}
        />

        <Letter form={form} disabled={false} showBtn />

        <FooterButtons
          form={form}
          isLoading={isLoading || isSaving}
          disabled={false}
          close={close}
          onSave={onSave}
        />
      </Form>
    </Row>
  );
};
