import { FormInstance } from "antd";
import { useCallback } from "react";

export const useAttachmentTools = (
  form: FormInstance,
  setGlobalIsLoading: (value: boolean) => void
) => {
  const setError = useCallback(
    (value: string[]) => {
      form.setFields([
        {
          name: "templateId",
          errors: value,
        },
      ]);
    },
    [form]
  );

  const onFail = useCallback(
    (interval: any) => {
      setGlobalIsLoading(false);

      clearInterval(interval);

      setError(["Generation failed"]);
    },
    [setError, setGlobalIsLoading]
  );

  return { setError, onFail };
};
