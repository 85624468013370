import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import { useCalcTotals } from "./calculations/useCalcTotals";
import { useTransformAmounts } from "../../../../../../Payment/hooks/useTransformAmounts";
import { useCalcBeforeBalances } from "./calculations/useCalcBeforeBalances";
import { useCalcAfter } from "./calculations/useCalcAfter";
import { useCalcLateChargeAfter } from "./calculations/useCalcLateChargeAfter";
import { useCalcPrincipalToBalance } from "./calculations/useCalcPrincipalToBalance";

export const useCalculate = (
  form: FormInstance,
  instance: any,
  contractId: string,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  setForm: (data: any, isFormBlured: boolean) => void
) => {
  const [isLCManualyChanged, setIsLCManualyChanged] = useState<boolean>(false);

  const { transform } = useTransformAmounts();

  const { calcTotalToDisburse } = useCalcTotals();

  const { calcAfterBalance, calcAfter, calcInterestAfter } = useCalcAfter();

  const { calcPrincipalToBalance } = useCalcPrincipalToBalance();

  const { calcBeforeBalances } = useCalcBeforeBalances(
    contractId,
    setIsDisabled,
    calcAfter,
    calcInterestAfter
  );

  const { calcLateChargeAfter } = useCalcLateChargeAfter();

  const calculate = useCallback(
    async (toUpdate?: { name: string; value: any }) => {
      try {
        let isChanged: boolean = isLCManualyChanged;

        const history = await form.validateFields();

        const data = transform({
          ...instance,
          ...history,
          breakdown: {
            ...instance.breakdown,
            ...history.breakdown,
          },
          disbursements:
            toUpdate && toUpdate.name === "disbursements"
              ? toUpdate.value
              : instance.disbursements,
        });

        if (toUpdate && toUpdate.name === "unpaid" && !isLCManualyChanged) {
          setIsLCManualyChanged(true);
          isChanged = true;
        }

        if (toUpdate && toUpdate.name === "interestAmount") {
          calcInterestAfter(data);
        }

        calcPrincipalToBalance(data);

        calcTotalToDisburse(data, toUpdate);

        await calcBeforeBalances(data, isChanged, toUpdate);

        calcAfterBalance(data, toUpdate);

        calcLateChargeAfter(data, isChanged);

        setForm(data, false);

        return 1;
      } catch (error) {
        return 0;
      }
    },
    [
      form,
      instance,
      setForm,
      transform,
      calcTotalToDisburse,
      calcBeforeBalances,
      calcAfterBalance,
      calcLateChargeAfter,
      calcPrincipalToBalance,
      calcInterestAfter,
      isLCManualyChanged,
    ]
  );

  return { calculate, isLCManualyChanged, setIsLCManualyChanged };
};
