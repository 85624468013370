import { Button, Radio, RadioChangeEvent, Row, Tooltip } from "antd";
import { usePostAll } from "../hooks/usePostAll";
import { IOption } from "../../../../../../../types/Option";
import { useCallback, useMemo } from "react";
import { usePrintReceipts } from "../hooks/usePrintReceipts";

type HeaderProps = {
  batchId: string;
  request: any;
  reloadBatch: () => void;
  setRequest: React.Dispatch<React.SetStateAction<any>>;
};

export const Header = ({
  batchId,
  request,
  reloadBatch,
  setRequest,
}: HeaderProps) => {
  const { isLoading: isPostAllLoading, onPostAll } = usePostAll(
    batchId,
    reloadBatch
  );

  const { isDownloading, print: printReceipts } = usePrintReceipts(batchId);

  const radioOptions: IOption[] = useMemo(
    () => [
      { value: 0, label: "All" },
      { value: 1, label: "Posted" },
      { value: 2, label: "Draft" },
    ],
    []
  );

  const onRadioChange = useCallback(
    (e: RadioChangeEvent): void =>
      setRequest({ ...request, status: e.target.value }),
    [request, setRequest]
  );

  return (
    <Row className="w-100 d-flex justify-space-between pb-6">
      <Radio.Group
        optionType="button"
        options={radioOptions}
        onChange={onRadioChange}
        defaultValue={0}
      />

      <Row>
        <Button shape="round" disabled={isPostAllLoading} onClick={onPostAll}>
          Post All Drafts
        </Button>

        <Tooltip title="Print Payment Receipts">
          <Button
            shape="round"
            className="ml-4"
            disabled={isDownloading}
            onClick={printReceipts}
          >
            Print Receipts
          </Button>
        </Tooltip>
      </Row>
    </Row>
  );
};
