import { Button, Row, Table, TablePaginationConfig } from "antd";
import { useMemo } from "react";
import { useGetAccountsTableColumns } from "./hooks/getAccountsTableColumns";
import { useLoadData } from "./hooks/loadData";
import { DownloadOutlined } from "@ant-design/icons";
import { usePrintInvestorsReport } from "./hooks/usePrintInvestorsReport";

type PortalAccessProps = {
  customerId: string;
};

export const Accounts = ({ customerId }: PortalAccessProps) => {
  const { columns } = useGetAccountsTableColumns();

  const { isLoading: isPrinting, onPrint } =
    usePrintInvestorsReport(customerId);

  const { data, total, isLoading } = useLoadData(customerId);

  const scroll = useMemo(() => {
    return data.length > 5 ? { y: 185 } : undefined;
  }, [data]);

  const paginationProps: false | TablePaginationConfig = useMemo(() => {
    return total <= 10
      ? false
      : {
          size: "small",
        };
  }, [total]);

  return (
    <Row className="justify-space-between align-center">
      <Row className="card-header justify-space-between align-center w-100">
        <h5 className="h h5 card-header">Accounts</h5>
        <Button
          disabled={isPrinting}
          shape="round"
          className="btn"
          icon={<DownloadOutlined />}
          onClick={onPrint}
        >
          Investor Report
        </Button>
      </Row>
      <Row className="mt-6 table-small">
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          pagination={paginationProps}
          className="overflow-y-auto"
          rowKey="id"
          sticky
          scroll={scroll}
        />
      </Row>
    </Row>
  );
};
