import { Form, FormInstance } from "antd";
import { CustomModal } from "../../../../../../../../../lib/CustomModal";
import { PrintForm } from "./components/PrintForm";
import { useCallback } from "react";
import { Dayjs } from "dayjs";

type CouponsModalProps = {
  contractId: string;
  isOpen: boolean;
  nextDueDate: Dayjs | null;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CouponsModal = ({
  contractId,
  isOpen,
  nextDueDate,
  setIsOpen,
}: CouponsModalProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const close = useCallback(
    (value: boolean) => {
      form.resetFields();
      setIsOpen(value);
    },
    [form, setIsOpen]
  );

  return (
    <CustomModal
      isModalOpen={isOpen}
      setIsModalOpen={close}
      width={400}
      titleText="Print Coupons"
      headerClass="processing-print-modal--header"
      modalClass="processing-print-modal"
    >
      <PrintForm
        contractId={contractId}
        form={form}
        nextDueDate={nextDueDate}
        close={close}
      />
    </CustomModal>
  );
};
