import { Button, Row } from "antd";
import { Outlet } from "react-router-dom";
import { Copyright } from "../../Copyright";
import { TermsAndConditionsModal } from "../../TermsAndConditionsModal";
import { useToggleTerms } from "../../../lib/hooks/useToggleTerms";
import "./styles.scss";

export const Footer = () => {
  const { termsTabId, setTermsTabId, onCloseTerms, openTerms, openPrivacy } =
    useToggleTerms();

  return (
    <>
      <Outlet />

      <Row className="px-8 py-3 footer-logged-in__container position-fixed align-center z-100">
        <Copyright />
        <Row className="footer-link align-center">
          <div className="elipse ml-2" />
          <Button
            type="link"
            className="footer-text pl-2 pr-0 py-0"
            onClick={openTerms}
          >
            Terms of Use
          </Button>
          <div className="elipse ml-2" />
          <Button
            type="link"
            className="footer-text px-2 py-0"
            onClick={openPrivacy}
          >
            Privacy Policy
          </Button>
        </Row>
      </Row>

      <TermsAndConditionsModal
        termsTabId={termsTabId}
        setTermsTabId={setTermsTabId}
        onClose={onCloseTerms}
      />
    </>
  );
};
