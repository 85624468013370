import { Card, Row, Table } from "antd";
import MailingsService from "../../../../../../services/accounts/mailings";
import { useFetchData } from "../../../../../../lib/hooks/useDefaultFetchData";
import { TRequest } from "../Ticklers/types";
import { useTableColumns } from "./hooks/useTableColumns";
import { TablePagination } from "../../../../../../components/TablePagination";
import { useCallback, useState } from "react";
import { useFetchDataCount } from "../../../../../../lib/hooks/useDefaultFetchDataCount";
import { Header } from "./components/Header";
import { Modals } from "./components/Modals";

type MailingsProps = {
  contractId: string;
  mailing: any;
  reloadAccount: () => void;
};

export const Mailings = ({
  contractId,
  mailing,
  reloadAccount,
}: MailingsProps) => {
  const {
    request,
    isLoading: isCountLoading,
    total,
    onPageChange,
    setRequest,
    fetchCount,
  } = useFetchDataCount<{ ContractId: string }>(
    MailingsService,
    {
      ContractId: contractId,
    },
    ["subjectTypes"]
  );

  const [itemToOpen, setItemToOpen] = useState<{
    id: string;
    type: 1 | 2;
  } | null>(null);

  const { data, isLoading, fetchData } = useFetchData<TRequest, any>(
    MailingsService,
    request
  );

  const reloadData = useCallback((): void => {
    fetchData();
    fetchCount();
    reloadAccount();
  }, [fetchCount, fetchData, reloadAccount]);

  const { columns } = useTableColumns(setItemToOpen, reloadData);

  const onChange = useCallback(
    (_: any, filters: any) => {
      const { receiverName } = filters;

      setRequest((prev: any) => {
        const req: any = { ...prev };

        if (receiverName) {
          req.subjectTypes = receiverName;
        } else {
          delete req.subjectTypes;
        }

        return req;
      });
    },
    [setRequest]
  );

  const onRow = useCallback(
    (record: any) => ({
      onClick: () => {
        const { id, type } = record;

        setItemToOpen({ id, type });
      },
    }),
    [setItemToOpen]
  );

  return (
    <Row className="h-100 w-100 px-8 py-6 card-table">
      <Card className="w-100 h-100">
        <Header
          reloadData={reloadData}
          contractId={contractId}
          mailing={mailing}
        />
        <Row className="w-100 pt-6 d-block card-table__body">
          <Table
            dataSource={data}
            loading={isLoading}
            pagination={false}
            rowKey="id"
            columns={columns}
            className="w-100 h-100 clickable-rows-table"
            onChange={onChange}
            onRow={onRow}
          />
          {total > 20 && (
            <TablePagination
              isTotalLoading={isCountLoading}
              total={total}
              currentPage={request.pageNumber}
              pageSize={request.pageSize}
              onPageChange={onPageChange}
            />
          )}
        </Row>
      </Card>
      <Modals
        contractId={contractId}
        itemToOpen={itemToOpen}
        setItemToOpen={setItemToOpen}
      />
    </Row>
  );
};
