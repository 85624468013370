import { Col, FormInstance, Row, Tooltip } from "antd";
import { ReactNode, useCallback, useMemo } from "react";
import { getFormattedCurrency } from "../../../../lib/currencyFormatter";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FloatNumberInput } from "../../../../components/FloatNumberInput";

type FeesAndTaxesProps = {
  instance: any;
  form: FormInstance;
  calculate: (toUpdate?: { name: string; value: any }) => void;
  isHistoryOpened: boolean;
};

export const FeesAndTaxes = ({
  instance,
  form,
  calculate,
  isHistoryOpened,
}: FeesAndTaxesProps) => {
  const totalPayment: string = useMemo(() => {
    return getFormattedCurrency(instance ? instance.totalPayment : "0");
  }, [instance]);

  const totalToDisburse: string = useMemo(() => {
    return getFormattedCurrency(instance ? instance.totalToDisburse : "0");
  }, [instance]);

  const totalToDisburseTooltipTitle: ReactNode = useMemo(() => {
    return (
      <>
        <div>Total Payment = Payment amount + From Impound amount</div>
        <div>
          Total to Disburse = Total disbursements amount + Total Fees and Taxes
        </div>
      </>
    );
  }, []);

  const tooltipStyle: any = useMemo(
    () => ({
      maxWidth: "425px",
    }),
    []
  );

  const isEqualAmounts: boolean = useMemo(() => {
    return totalPayment === totalToDisburse;
  }, [totalPayment, totalToDisburse]);

  const columnSize = useMemo(
    () => (isHistoryOpened ? 24 : 12),
    [isHistoryOpened]
  );

  const getInputCol = useCallback(
    (name: string, label: string, isLeft: boolean): ReactNode => {
      return (
        <Col span={columnSize}>
          <FloatNumberInput
            name={name}
            label={label}
            form={form}
            className={`totals-input mb-4 ${isLeft ? "mr" : "ml"}-1`}
            onPressEnter={() => calculate({ name, value: true })}
            includeThousandsSeparator
          />
        </Col>
      );
    },
    [calculate, columnSize, form]
  );

  return (
    <Row>
      <Row className={`w-100 ${isEqualAmounts ? "mb-7" : "mb-2"}`}>
        <Col span={12}>
          <p className="my-0 text-sm mb-1">Total Payment</p>
          <p
            className={`my-0 text elipsis w-500 ${
              !isEqualAmounts && "text-color-red"
            }`}
          >
            {totalPayment}
          </p>
        </Col>
        <Col span={12}>
          <Row className="align-center mb-1">
            <p className="my-0 text-sm mr-1">Total to Disburse</p>
            <Tooltip
              title={totalToDisburseTooltipTitle}
              overlayStyle={tooltipStyle}
              placement="top"
              overlayClassName="text-sm"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Row>
          <p
            className={`my-0 text elipsis w-500 ${
              !isEqualAmounts && "text-color-red"
            }`}
          >
            {totalToDisburse}
          </p>
        </Col>
      </Row>
      {!isEqualAmounts && (
        <Row className="mb-1">
          <p className="my-0 text-sm text-color-red">
            Total amounts should be equal.
          </p>
        </Row>
      )}
      <Row className="w-100">
        {getInputCol("payerFee", "Payer Fee", true)}
        {!isHistoryOpened && getInputCol("payerTax", "Payer Tax", false)}
      </Row>
      <Row className="w-100">
        {getInputCol("payeeFee", "Payee Fee", true)}
        {!isHistoryOpened && getInputCol("payeeTax", "Payee Tax", false)}
      </Row>
    </Row>
  );
};
