import { useMemo } from "react";
import { IOption } from "../../../../../../../../../../types/Option";

export const useFormattedOptions = (data: any, value: string) => {
  const options: IOption[] = useMemo(() => {
    const payerIdsSet: Set<string> = new Set(
      data.map((item: any) => item[value])
    );

    const payerIds: string[] = Array.from(payerIdsSet);

    const payers: any[] = payerIds
      .map((id: string) => data.find((item: any) => item[value] === id))
      .filter((item: any | undefined) => !!item);

    return payers.map((item: any) => ({
      value: item[value],
      label: item.payerName,
    }));
  }, [data, value]);

  return { options };
};
