import { Button, Row } from "antd";

type FormButtonsProps = {
  disabled: boolean;
  close: () => void;
  onSave: () => void;
};

export const FormButtons = ({ disabled, close, onSave }: FormButtonsProps) => {
  return (
    <Row className="w-100 justify-end pa-4">
      <Button
        type="primary"
        shape="round"
        disabled={disabled}
        loading={disabled}
        onClick={onSave}
      >
        Print
      </Button>
      <Button
        shape="round"
        className="ml-2"
        disabled={disabled}
        onClick={close}
      >
        Cancel
      </Button>
    </Row>
  );
};
