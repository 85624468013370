import { useState, useMemo, useEffect, useCallback } from "react";
import { SecurityCheck } from "../../components/SecurityCheck";
import { SendNewPasswordForm } from "./SendNewPasswordForm";
import { clearMessage } from "../../acions/actionsCreators/messageActions";
import { useAppDispatch } from "../../store";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  sendVerificationCode,
  confirmVerificationCode,
} from "../../acions/auth";
import { useGetFormattedPhoneSecure } from "../../lib/hooks/getFormattedPhoneSecure";
import { getFormatted } from "../../lib/PhoneFormatter";
import { EmailCodeExpired } from "./EmailCodeExpired";
import ROUTES from "../../configs/routesConfig";
import { Spin } from "antd";
import { IssueWithAccount } from "../../components/IssueWithAccount";
import { ISSUE_WITH_ACCOUNT } from "../../lib/constants";

export const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();

  const [isFirstStep, setIsFirstStep] = useState<boolean>(true);

  const [data, setData] = useState<any>({});

  const [userName, setUserName] = useState<string | null>(null);

  const [phone, setPhone] = useState<string | undefined>(undefined);

  const [verificationCodeId, setVerificationCodeId] = useState<string | null>(
    null
  );

  const [emailCode, setEmailCode] = useState<string | null>("");

  const [isEmailCodeExpired, setIsEmailCodeExpired] = useState<boolean>(false);

  const [hasIssueWithAccount, setHasIssueWithAccount] =
    useState<boolean>(false);

  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);

  const [formattedPhone] = useGetFormattedPhoneSecure({
    phone: getFormatted(phone).substring(2),
  });

  const securityCheckSubTitle = useMemo(() => {
    return (
      <div className="sub-title__container mb-10">
        <p className="justify-center my-0">We just want to be sure it’s you.</p>
        <p className="justify-center my-0">
          Enter the code we’ve just sent to
          <span className="email-text">&ensp;{formattedPhone}</span>
        </p>
      </div>
    );
  }, [formattedPhone]);

  const sendCode = useCallback(
    async (value: any) => {
      dispatch(clearMessage());
      setIsConfirmDisabled(true);
      try {
        const response: any = await Promise.resolve(
          dispatch(sendVerificationCode("ForgotPassword", value))
        );

        setVerificationCodeId(response.data.verificationCodeId);
        setIsConfirmDisabled(false);

        return response;
      } catch (e: any) {
        setIsConfirmDisabled(false);

        e.hasIssueWithAccount && setHasIssueWithAccount(true);

        e.isExpiredCode && setIsEmailCodeExpired(true);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      !searchParams.get("user") ||
      !searchParams.get("emailCode") ||
      !searchParams.get("phone")
    ) {
      navigate(ROUTES.HOME);
    } else {
      setUserName(searchParams.get("user"));

      const code = searchParams.get("emailCode");

      setEmailCode(code);

      sendCode({ userName: searchParams.get("user"), emailCode: code });

      setPhone(searchParams.get("phone") || undefined);
    }
  }, [navigate, searchParams, sendCode]);

  const onSuccess = (values: any) => {
    const code = values.securityCode.replace(/-/g, "");

    setIsConfirmDisabled(true);
    dispatch(clearMessage());

    const request = {
      code,
      verificationCodeId,
      userName,
    };

    Promise.resolve(
      dispatch(confirmVerificationCode("ForgotPassword", request))
    )
      .then(() => {
        setIsFirstStep(false);
        setIsConfirmDisabled(false);
      })
      .catch(() => setIsConfirmDisabled(false));
  };

  const resend = useCallback(
    () => sendCode({ userName, emailCode }),
    [emailCode, sendCode, userName]
  );

  return (
    <>
      {isFirstStep ? (
        <>
          {!isConfirmDisabled ? (
            <>
              {!isEmailCodeExpired && !hasIssueWithAccount && (
                <SecurityCheck
                  header="Quick security check"
                  setStep={() => setIsFirstStep(true)}
                  isRememberCode={false}
                  isTimer={true}
                  subTitle={securityCheckSubTitle}
                  onConfirm={(value) => onSuccess(value)}
                  type="SMS"
                  removeBackButton={true}
                  onResend={resend}
                  isConfirmDisabled={isConfirmDisabled}
                />
              )}

              {isEmailCodeExpired && <EmailCodeExpired userName={userName} />}

              {hasIssueWithAccount && (
                <IssueWithAccount
                  header={ISSUE_WITH_ACCOUNT}
                  username={atob(userName || "")}
                />
              )}
            </>
          ) : (
            <div className="d-flex justify-center mt-16 pt-16">
              <Spin size="large" />
            </div>
          )}
        </>
      ) : (
        <SendNewPasswordForm
          data={data}
          setData={setData}
          setIsFirstStep={setIsFirstStep}
          request={{
            userName,
            verificationCodeId,
            emailCode: searchParams.get("emailCode"),
          }}
        />
      )}
    </>
  );
};
