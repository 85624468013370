import { Col, Form, FormInstance, Row, Select } from "antd";
import {
  ACCOUNT_STATUSES,
  PAYMENT_RESTRICTIONS,
} from "../../../../../../../../lib/constants";
import { Balance } from "./Sections/Balance";
import { NextPayment } from "./Sections/NextPayment";
import { RegularAmount } from "./Sections/RegularAmount";
import { useEffect, useMemo } from "react";

type OtherInfoProps = {
  data: any;
  form: FormInstance;
  isDisabled: boolean;
  lCDays1: number;
};

export const OtherInfo = ({
  data,
  form,
  isDisabled,
  lCDays1,
}: OtherInfoProps) => {
  const status = Form.useWatch("status", {
    form,
    preserve: true,
  });

  const isPaymentRestrictionsDisabled = useMemo(() => status === 8, [status]);

  useEffect(() => {
    status === 8 && form.setFieldValue("paymentRestrictions", 1);
  }, [form, status]);

  const hasPaymentHistory: boolean = useMemo(
    () => (data ? data.hasHistory : false),
    [data]
  );

  return (
    <>
      <h5 className="h h5 card-header">Status</h5>
      <Row className="mt-6">
        <Row className="w-100">
          <Col span={12} className="pr-2">
            <Form.Item label="Status" name={"status"}>
              <Select options={ACCOUNT_STATUSES} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Payments Restriction"
              name={"paymentRestrictions"}
            >
              <Select
                options={PAYMENT_RESTRICTIONS}
                disabled={isPaymentRestrictionsDisabled || isDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </Row>
      <Balance form={form} hasPaymentHistory={hasPaymentHistory} />
      <NextPayment form={form} data={data} lCDays1={lCDays1} />
      {data && <RegularAmount data={data} form={form} />}
    </>
  );
};
