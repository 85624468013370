import { useCallback, useState } from "react";
import ReportService from "../../../../../../../services/reportGeneration";
import { useDownloadReport } from "../../../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../../../lib/hooks/useOpenProgressModal";

export const usePrintInvestorsReport = (customerId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { download } = useDownloadReport(
    setIsLoading,
    "application/octet-stream"
  );

  const { openModal } = useOpenProgressModal(
    "Generating Investors Summary Report",
    "Generating Investors Summary Report failed",
    (id?: string) => download(ReportService, id)
  );

  const onPrint = useCallback(async () => {
    try {
      setIsLoading(true);

      const request = {
        customerId,
        reportFor: 0,
      };

      const id: string = await ReportService.generateReportByName(
        "InvestorSummaryReport",
        request
      );

      openModal(id);
    } catch {
      setIsLoading(false);
    }
  }, [customerId, openModal]);

  return { isLoading, onPrint };
};
