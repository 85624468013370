import { Row } from "antd";
import { ReactNode, useCallback } from "react";

export const useGetTooltip = () => {
  const getTooltip = useCallback(
    (item: any, value: string): string | ReactNode => {
      if (item) {
        if (item.isEmail) {
          return item.name;
        } else {
          return (
            <Row className="d-block">
              <p className="my-0 text-sm">#{item.number}</p>
              <p className="my-0 text-sm">{item.email}</p>
            </Row>
          );
        }
      } else {
        return value;
      }
    },
    []
  );

  return { getTooltip };
};
