import { ReactNode, useCallback, useMemo } from "react";
import { Modal } from "../../../Modal";
import { Button, Form, FormInstance, Row } from "antd";
import { ConfigForm } from "./ConfigForm";
import { GeneralConfiguration } from "./GeneralConfiguration";

type LateChargesModalProps = {
  contractId: string;
  isOpen: boolean;
  form: FormInstance;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  calculate: (toUpdate?: {
    name: string;
    value: any;
    isUpdatedField?: boolean;
  }) => void;
  isHistoryOpened: boolean;
};

export const LateChargesModal = ({
  contractId,
  isOpen,
  form,
  setIsOpen,
  isFormDisabled,
  isPaymentPosted,
  calculate,
  isHistoryOpened,
}: LateChargesModalProps) => {
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  const title: ReactNode = useMemo(() => {
    return (
      <Row className="align-center">
        <h5 className="h h5">Late Charge Configuration</h5>
      </Row>
    );
  }, []);

  const closeBtn: ReactNode = useMemo(
    () => (
      <Button
        type="primary"
        shape="round"
        onClick={closeModal}
        disabled={isFormDisabled}
        loading={isFormDisabled}
      >
        Close
      </Button>
    ),
    [closeModal, isFormDisabled]
  );

  return (
    <Modal isOpen={isOpen} title={title} footer={closeBtn} width={670}>
      <Row className="px-8 pb-6 pt-8 late-charges-popup">
        <Form
          className="w-100"
          form={form}
          disabled={isFormDisabled || isPaymentPosted}
        >
          <ConfigForm
            form={form}
            calculate={calculate}
            isHistoryOpened={isHistoryOpened}
          />
        </Form>
        <GeneralConfiguration contractId={contractId} />
      </Row>
    </Modal>
  );
};
