import { useCallback } from "react";

export const useGetDefaultRequest = () => {
  const getRequest = useCallback((res: any) => {
    const request: any = {
      dateFrom: res.dates[0].format(),
      dateTo: res.dates[1].format(),
    };

    if (res.batchType !== 0) {
      request.batchType = res.batchType;
    }

    return request;
  }, []);

  return { getRequest };
};
