import { useCallback } from "react";
import PaymentHistoryService from "../../../../../../../../services/accounts/paymentHistory";

export const useCalcBeforeBalances = (
  contractId: string,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  calcAfter: (history: any, name: string) => void,
  calcInterestAfter: (history: any) => void
) => {
  const setBeforeAndAfter = useCallback(
    (history: any, res: any, name: string) => {
      history.breakdown[`${name}Before`] = res.breakdown[`${name}Before`];

      calcAfter(history, name);
    },
    [calcAfter]
  );

  const calcBeforeBalances = useCallback(
    async (
      history: any,
      isChanged: boolean,
      toUpdate?: { name: string; value: any }
    ) => {
      if (toUpdate && toUpdate.name === "date") {
        setIsDisabled(true);

        const res: any = await PaymentHistoryService.initialize(
          contractId,
          history.date.format()
        );

        setIsDisabled(false);

        ["principal", "fromImpound"].forEach((name: string) => {
          setBeforeAndAfter(history, res, name);
        });

        calcInterestAfter(history);

        if (!isChanged) {
          history.breakdown.lateChargeBefore = res.breakdown.lateChargeBefore;
        }
      }
    },
    [setIsDisabled, contractId, setBeforeAndAfter, calcInterestAfter]
  );

  return { calcBeforeBalances };
};
