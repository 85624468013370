import { useCallback, useState } from "react";
import { useDownloadReport } from "../../../../../../../lib/hooks/useDownloadReport";
import { useAppDispatch } from "../../../../../../../store";
import ReportService from "../../../../../../../services/reportGeneration";
import { useOpenProgressModal } from "../../../../../../../lib/hooks/useOpenProgressModal";
import { setErrorMessage } from "../../../../../../../acions/actionsCreators/messageActions";

export const usePrintReceipts = (batchId: string) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { download } = useDownloadReport(setIsDownloading);

  const onSuccess = useCallback(
    (id?: string) => download(ReportService, id),
    [download]
  );

  const { openModal } = useOpenProgressModal(
    "Generating Payment Receipts Report",
    "Generating Payment Receipts Report failed",
    onSuccess
  );

  const print = useCallback(async () => {
    try {
      setIsDownloading(true);

      const id: string = await ReportService.generateReceiptReportByName(
        "PaymentReceipt",
        batchId
      );

      openModal(id);
    } catch (err: any) {
      dispatch(setErrorMessage(err.response.data.title, true));

      setIsDownloading(false);
    }
  }, [setIsDownloading, batchId, dispatch, openModal]);

  return { isDownloading, print };
};
