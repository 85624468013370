import { Row } from "antd";
import { TicklersModal } from "./components/TicklersModal";

export const Reports = () => {
  return (
    <Row className="d-block px-8">
      <Row className="my-6">
        <h3 className="h h3">Reports</h3>
      </Row>
      <Row className="py-2">
        <TicklersModal />
      </Row>
    </Row>
  );
};
