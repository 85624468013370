import { useCallback } from "react";
import { useDownloadReport } from "../../../../../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../../../../../lib/hooks/useOpenProgressModal";
import ReportService from "../../../../../../../../../services/reportGeneration";

export const usePrintAccountInfo = (
  contractId: string,
  setIsPrinting: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { download } = useDownloadReport(setIsPrinting);

  const { openModal } = useOpenProgressModal(
    "Generating Account Info Report",
    "Generating Account Info Report failed",
    (id?: string) => download(ReportService, id)
  );

  const printAccountInfo = useCallback(async () => {
    try {
      setIsPrinting(true);

      const id: string = await ReportService.generateReportByName(
        "ContractInfoReport",
        {
          contractId,
        }
      );

      openModal(id);

      setIsPrinting(false);
    } catch {
      setIsPrinting(false);
    }
  }, [setIsPrinting, contractId, openModal]);

  return { printAccountInfo };
};
