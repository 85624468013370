import { Form, FormInstance, Select } from "antd";
import { useCallback, useMemo } from "react";
import { PAYEE_TYPES } from "../../../../../../../../../../lib/constants";

type TypeSelectProps = {
  form: FormInstance;
  isHistoryOpened: boolean;
  isPassthru: boolean | undefined;
  setIsRadioVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPercentage: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TypeSelect = ({
  form,
  isHistoryOpened,
  isPassthru,
  setIsRadioVisible,
  setIsPercentage,
}: TypeSelectProps) => {
  const payeeTypes = useMemo(() => {
    let res = PAYEE_TYPES.filter((item: any) => item.value !== 101);

    res = res.filter((item: any) =>
      !isHistoryOpened ? item.value !== 102 : item.value !== 7
    );

    return res;
  }, [isHistoryOpened]);

  const onSelectChange = useCallback(
    (e: number) => {
      const isDollarOnly: boolean = [5, 6, 7].includes(e) && !isPassthru;

      setIsRadioVisible(!isDollarOnly);

      if (isDollarOnly) {
        setIsPercentage(false);
      } else {
        setIsPercentage(form.getFieldValue("amountSwitcher") === 2);
      }
    },
    [form, setIsPercentage, setIsRadioVisible, isPassthru]
  );

  return (
    <Form.Item name="type" label="Payee Type" className="mb-4">
      <Select options={payeeTypes} onChange={onSelectChange} />
    </Form.Item>
  );
};
