import { Button, Dropdown, MenuProps, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useCallback, useMemo } from "react";
import { useButtonHandlers } from "../../Batch/components/BatchDetails/components/PaymentsTable/hooks/useButtonHandlers";
import { NavigateFunction, useNavigate } from "react-router-dom";

type PostPaymentButtonsProps = {
  paymentId: string;
  isActionBtnDisabled: boolean;
  isPosted: boolean;
  save: (action: string, onSaved: () => void) => void;
  post: (id: string) => void;
};

export const PostPaymentButtons = ({
  paymentId,
  isActionBtnDisabled,
  isPosted,
  save,
  post,
}: PostPaymentButtonsProps) => {
  const navigate: NavigateFunction = useNavigate();

  const isCreate: boolean = useMemo(() => paymentId === "create", [paymentId]);

  const back = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  const { onDeleteBtnClick } = useButtonHandlers(back);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <div onClick={() => save("save", back)}>Save as Draft</div>,
      },
    ],
    [back, save]
  );

  const onDelete = useCallback((): void => {
    !isCreate && onDeleteBtnClick(paymentId);
  }, [isCreate, onDeleteBtnClick, paymentId]);

  return (
    <Row>
      {isCreate && (
        <Dropdown.Button
          className="mr-4 actions-dropdown"
          menu={{ items }}
          disabled={isActionBtnDisabled}
          type="primary"
          icon={<DownOutlined />}
          trigger={["click"]}
          onClick={() => save("post", back)}
        >
          Post Payment
        </Dropdown.Button>
      )}

      {!isCreate && !isPosted && (
        <>
          <Button
            className="mr-4"
            disabled={isActionBtnDisabled}
            type="primary"
            shape="round"
            onClick={() => post(paymentId)}
          >
            Post Payment
          </Button>
          <Button
            shape="round"
            disabled={isActionBtnDisabled}
            onClick={onDelete}
          >
            Delete
          </Button>
        </>
      )}
    </Row>
  );
};
