import { Row } from "antd";
import { useMemo } from "react";
import { Warning } from "./Warning";

type HeaderProps = {
  instance: any;
};

export const Header = ({ instance }: HeaderProps) => {
  const diff: number = useMemo(() => {
    if (!instance) {
      return -1;
    }

    const { dueDateNext, date } = instance;

    return date.diff(dueDateNext, "d");
  }, [instance]);

  return (
    <Row className="align-center mb-4 w-100">
      <p className="my-0 text-md card-header w-500">Payment Breakdown</p>
      {!instance || !instance.dueDateNext || diff <= 0 ? (
        <Row />
      ) : (
        <Warning diff={diff} lateCharge={instance.breakdown.lateCharge} />
      )}
    </Row>
  );
};
