import { IOption } from "../../../../../../../../../../types/Option";
import { Checkbox, Form } from "antd";

type PayersListProps = {
  options: IOption[];
};

export const PayersList = ({ options }: PayersListProps) => {
  return (
    <Form.Item label="Payer" name="payerIds" className="payers-list mb-0">
      <Checkbox.Group options={options} />
    </Form.Item>
  );
};
