import { Button, Row } from "antd";
import { ColumnsType } from "antd/es/table";
import { useMemo, useState } from "react";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import { useDeleteHandler } from "./formHandlers/deleteHandler";

export const useGetBankAccountsTableColumns = (
  onOpenSideBar: (value: any | null) => void,
  reload: () => void
) => {
  const [isActionsDisabled, setIsActionsDisabled] = useState<boolean>(false);

  const { onRemoveClicked } = useDeleteHandler();

  const columns: ColumnsType<any> = useMemo(() => {
    return [
      {
        title: "Account Number",
        dataIndex: "bankAccountNumber",
        width: "45%",
        ellipsis: true,
      },
      {
        title: "Primary",
        dataIndex: "isPrimary",
        width: "30%",
        render: (value: boolean) => (value ? "Yes" : "No"),
      },
      {
        title: "",
        width: "25%",
        render: (_: unknown, record: any) => {
          return (
            <Row className="table-actions">
              <Button
                className="mr-4 px-0 py-0"
                type="link"
                onClick={() => onOpenSideBar(record)}
                icon={<EditOutlined className="text-md title" />}
                disabled={isActionsDisabled}
              />
              <Button
                className="px-0 py-0"
                type="link"
                onClick={() =>
                  onRemoveClicked(record.id, setIsActionsDisabled, reload)
                }
                icon={<CloseOutlined className="text-md title" />}
                disabled={isActionsDisabled}
              />
            </Row>
          );
        },
      },
    ];
  }, [isActionsDisabled, onOpenSideBar, onRemoveClicked, reload]);

  return [columns];
};
