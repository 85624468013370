import { useCallback } from "react";

export const useCalcLateChargeAfter = () => {
  const calcLateChargeAfter = useCallback(
    (history: any, isChanged: boolean) => {
      if (!isChanged) {
        history.breakdown.lateChargeAfter =
          (history.breakdown.lateChargeBefore || 0) +
          (history.breakdown.lateCharge.assessed || 0) -
          (history.breakdown.lateCharge.paid || 0) -
          (history.breakdown.lateCharge.addedToPrincipal || 0);
      }
    },
    []
  );

  return { calcLateChargeAfter };
};
