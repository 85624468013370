import qs from "qs";
import { API_URL } from "../configs/apiUrl";
import { UserListQuery } from "../types/UserListQuery";
import { authHeader } from "./data";
import authAxios from "./data";

const fetchAll = async (request: UserListQuery) => {
  const response = await authAxios.get(API_URL + "User/GetList", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetchRolesList = async () => {
  const response = await authAxios.get(API_URL + "User/GetRoles", {
    headers: authHeader(),
  });

  return response.data;
};

const registerAdmin = async (request: any) => {
  const response = await authAxios.post(
    API_URL + "User/RegisterAdmin",
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchUser = async (userId: string) => {
  const response = await authAxios.get(API_URL + "User/Get", {
    params: { id: userId },
    headers: authHeader(),
  });

  return response.data;
};

const blockUnblockUser = async (request: any, isBlock: boolean) => {
  const response = await authAxios.post(
    API_URL + `User/${isBlock ? "Block" : "Unblock"}`,
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchUserHistoryEvents = async (userId: string) => {
  const response = await authAxios.get(API_URL + "UserEvent", {
    params: { id: userId },
    headers: authHeader(),
  });

  return response.data;
};

const fetchUsersCount = async (request: any) => {
  const response = await authAxios.get(API_URL + "User/Count", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const updateUser = async (request: any, userId: string) => {
  const response = await authAxios.put(
    API_URL + "User/Modify?id=" + userId,
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const deleteUser = async (id: string) => {
  const response = await authAxios.delete(API_URL + "User/Delete?id=" + id, {
    headers: authHeader(),
  });
  return response.data;
};

const hasUserPortalAccess = async (id: string) => {
  const response = await authAxios.get(API_URL + "User/HasLinkedPortalAccess", {
    params: { id },
    headers: authHeader(),
  });

  return response.data;
};

export const UsersService = {
  fetchAll,
  fetchRolesList,
  registerAdmin,
  fetchUser,
  blockUnblockUser,
  fetchUserHistoryEvents,
  fetchUsersCount,
  updateUser,
  deleteUser,
  hasUserPortalAccess,
};
