import { Checkbox, Col, Form, FormInstance, Row, Select } from "antd";
import { useMemo } from "react";
import { MONTHS } from "../../../../../../../../../../lib/constants";
import { IOption } from "../../../../../../../../../../types/Option";
import dayjs, { Dayjs } from "dayjs";
import { FloatNumberInput } from "../../../../../../../../../../components/FloatNumberInput";
import { FormButtons } from "./FormButtons";
import { usePrintCoupons } from "../hooks/usePrintCoupons";
import { Rule } from "antd/es/form";

type PrintFormProps = {
  form: FormInstance;
  contractId: string;
  nextDueDate: Dayjs | null;
  close: (value: boolean) => void;
};

export const PrintForm = ({
  form,
  contractId,
  nextDueDate,
  close,
}: PrintFormProps) => {
  const months: IOption[] = useMemo(
    () =>
      MONTHS.map((item: string, i: number) => ({
        value: i + 1,
        label: item,
      })),
    []
  );

  const nextPaymentDue: boolean = Form.useWatch("nextPaymentDue", form);

  const initialValues = useMemo(() => {
    const month: string = dayjs(nextDueDate).format("MMMM");

    const monthValue: IOption | undefined = months.find(
      (item: IOption) => item.label === month
    );

    return {
      nextPaymentDue: true,
      numbersOfCoupons: 12,
      firstCouponMonth: monthValue ? monthValue.value : undefined,
    };
  }, [nextDueDate, months]);

  const isMonthDisabled: boolean = useMemo(
    () => nextPaymentDue,
    [nextPaymentDue]
  );

  const { isLoading, onPrint } = usePrintCoupons(contractId, form, close);

  const numberRule: Rule[] = useMemo(() => [{ required: true }], []);

  return (
    <>
      <Form
        layout="vertical"
        requiredMark={false}
        className="pt-6 processing-print-form"
        form={form}
        initialValues={initialValues}
        disabled={isLoading}
      >
        <Row className="d-block pb-5">
          <Row className="align-end">
            <Col span={10}>
              <Form.Item
                label="First Coupon"
                name="firstCouponMonth"
                className="mb-6 w-100"
              >
                <Select
                  options={months}
                  disabled={isMonthDisabled || isLoading}
                />
              </Form.Item>
            </Col>
            <Col className="ml-4">
              <Form.Item name="nextPaymentDue" valuePropName="checked">
                <Checkbox>Next Payment Due</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <FloatNumberInput
              form={form}
              name="numbersOfCoupons"
              label="Number of Coupons"
              prefix=""
              allowDecimal={false}
              rules={numberRule}
            />
          </Row>
        </Row>
      </Form>
      <FormButtons close={close} isLoading={isLoading} onPrint={onPrint} />
    </>
  );
};
