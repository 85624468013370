import { Col, Form, FormInstance, Input, Radio, Row, Select } from "antd";
import { RadioChangeEvent } from "antd";
import {
  MAX_ADDRESS_LINE,
  MAX_CITY,
  QUOTE_RECEIVER,
  RECEIVER_TYPE,
  WRONG_ZIP_FORMAT,
} from "../../../../../../../../lib/constants";
import { useGetEmailFormRules } from "../../../../../../../../lib/hooks/getEmailFormRules";
import { PhoneInput } from "../../../../../../../../components/PhoneInput";
import { useGetUSAStates } from "../../../../../../../../lib/hooks/getUSAStates";
import { ZIP_INPUT_RULE } from "../../../../../../../../lib/validationRules";
import { TFormattedCustomerData } from "../../types";
import { useCallback, useEffect } from "react";
import { useReceiverFormOptions } from "./hooks/useReceiverFormOptions";

const { TextArea } = Input;

type TReceiverInfoProps = {
  form: FormInstance;
  isFormDisabled: boolean;
  customersData:
    | {
        primaryBuyer: TFormattedCustomerData;
        primarySeller: TFormattedCustomerData;
      }
    | undefined;
  id: string | null;
};

export const ReceiverInfo = ({
  form,
  isFormDisabled,
  customersData,
  id,
}: TReceiverInfoProps) => {
  const quoteReceiver = Form.useWatch(["receiver", "type"], {
    form,
    preserve: true,
  });

  const receiverType = Form.useWatch("receiverType", {
    form,
    preserve: true,
  });

  const { formOptions } = useReceiverFormOptions(receiverType === 1);

  const { rules: emailRules } = useGetEmailFormRules(false);

  const { statesList } = useGetUSAStates(true);

  const onRadioChange = useCallback(
    (e: RadioChangeEvent): void => {
      const { value } = e.target;

      if (value === 3) {
        const { receiver } = form.getFieldsValue(["receiver"]);

        Object.keys(receiver).forEach((key) => {
          if (key !== "type") {
            receiver[key] = "";
          }
        });

        form.setFieldsValue({
          receiver,
          receiverType: 1,
        });

        return;
      }

      form.setFieldValue("receiverType", 1);

      if (customersData) {
        const { primaryBuyer, primarySeller } = customersData;

        const { receiver } = value === 1 ? primaryBuyer : primarySeller;

        form.setFieldsValue({
          receiverType: receiver.companyName ? 2 : 1,
          receiver,
        });
      }
    },
    [customersData, form]
  );
  useEffect(() => {
    id === null &&
      !isFormDisabled &&
      quoteReceiver === 1 &&
      customersData &&
      form.setFieldsValue(customersData.primaryBuyer);
  }, [customersData, form, id, quoteReceiver, isFormDisabled]);

  return (
    <Row className="w-100 divider pt-6 payoff-form-receiver-container ">
      <p className="w-100 text-md w-500 mb-4 mt-0">Receiver</p>
      <Col span={12} className="pr-13">
        <Form.Item
          label="Receiver"
          name={["receiver", "type"]}
          className="mb-4"
        >
          <Radio.Group
            options={QUOTE_RECEIVER}
            className="d-flex flex-column payoff-form-receiver-radio"
            onChange={onRadioChange}
          />
        </Form.Item>
        {quoteReceiver === 3 && (
          <Form.Item label="Type" name="receiverType" className="mb-4">
            <Radio.Group
              options={RECEIVER_TYPE}
              className="d-flex flex-column payoff-form-receiver-radio"
            />
          </Form.Item>
        )}
        {formOptions.map((item) => (
          <Form.Item
            label={item.label}
            name={item.name}
            rules={item.rules}
            key={item.label}
            className="mb-4"
          >
            <Input />
          </Form.Item>
        ))}
        <Form.Item
          label="Email"
          name={["receiver", "email"]}
          rules={emailRules}
          className="mb-4"
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <PhoneInput
          form={form}
          label="Phone"
          size="middle"
          name={["receiver", "phone"]}
          formItemMargin="mb-4"
          hideAddon
          notRequired
          disabled={isFormDisabled}
        />
        <Form.Item
          label="Address"
          name={["receiver", "addressLine"]}
          rules={[
            {
              max: 100,
              message: MAX_ADDRESS_LINE,
              validateTrigger: "onSubmit",
            },
          ]}
          className="mb-4"
        >
          <TextArea rows={2} className="payoff-form-textarea" />
        </Form.Item>
        <Form.Item
          label="City"
          name={["receiver", "city"]}
          className="w-100 mb-4"
          rules={[
            {
              max: 50,
              message: MAX_CITY,
              validateTrigger: "onSubmit",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row className="d-flex">
          <Form.Item
            label="State"
            name={["receiver", "state"]}
            className="mb-4 payoff-form-receiver-select"
          >
            <Select options={statesList} allowClear showSearch />
          </Form.Item>
          <Form.Item
            label="ZIP"
            name={["receiver", "zipCode"]}
            className="mb-4 ml-3"
            rules={[
              {
                pattern: ZIP_INPUT_RULE,
                message: WRONG_ZIP_FORMAT,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Row>
      </Col>
    </Row>
  );
};
