import { useCallback } from "react";

export const useInputDisabled = (
  name: string,
  isHistoryOpened: boolean,
  isPaymentPosted: boolean,
  isFormDisabled: boolean
) => {
  const getDisabled = useCallback(
    (names: string[]): boolean =>
      (names.includes(name) && !isHistoryOpened) ||
      isPaymentPosted ||
      isFormDisabled,
    [isFormDisabled, isHistoryOpened, isPaymentPosted, name]
  );

  return { getDisabled };
};
