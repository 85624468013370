import { useCallback, useEffect, useState } from "react";
import PayersService from "../../../../../../../../../../services/accounts/payers";

export const useFetchListData = (contractId: string, type: 0 | 1 | null) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [data, setData] = useState<any[]>([]);

  const fetchPayers = useCallback(async () => {
    try {
      const request = {
        pageNumber: 1,
        pageSize: 1000,
        contractId,
        for1098: true,
      };

      const res: any[] = await PayersService.fetchAll(request);

      setData(res);

      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [contractId]);

  const fetchPayees = useCallback(() => {
    setData([]);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (type !== null) {
      type === 0 ? fetchPayers() : fetchPayees();
    }
  }, [type, fetchPayers, fetchPayees]);

  return { data, isLoading };
};
