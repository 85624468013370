import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import ReportService from "../../../../../../../../../../services/reportGeneration";
import { useDownloadReport } from "../../../../../../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../../../../../../lib/hooks/useOpenProgressModal";
import { updateMailingKey } from "../../../../../../../../../../acions/actionsCreators/mailingKeyActions";
import { useAppDispatch } from "../../../../../../../../../../store";

export const usePrintCoupons = (
  contractId: string,
  form: FormInstance,
  close: (value: boolean) => void
) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { download } = useDownloadReport(setIsLoading);

  const { openModal } = useOpenProgressModal(
    "Generating Coupons Report",
    "Generating Coupons Report failed",
    (id?: string) => {
      download(ReportService, id);
      dispatch(updateMailingKey());
    }
  );

  const onPrint = useCallback(async () => {
    try {
      setIsLoading(true);

      const values: any = await form.validateFields();

      const id: string = await ReportService.generateReportByName(
        "Coupons/print",
        { ...values, contractId }
      );

      close(false);

      openModal(id);
    } catch {
      setIsLoading(false);
    }
  }, [form, openModal, contractId, close]);

  return { isLoading, onPrint };
};
