import { Form, FormInstance, Select } from "antd";
import { DatePicker } from "antd";
import { DATE_FORMAT } from "../../../../../lib/constants";
import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { useFetchTicklerTypes } from "../hooks/useFetchTicklerTypes";
import { FormButtons } from "./FormButtons";
import { usePrint } from "../hooks/usePrint";

const { RangePicker } = DatePicker;

type FormItemsProps = {
  form: FormInstance;
  closeModal: (value: boolean) => void;
};

export const FormItems = ({ form, closeModal }: FormItemsProps) => {
  const { isLoading, options } = useFetchTicklerTypes();

  const initialValues = useMemo(() => {
    const now = dayjs();

    return {
      dates: [now, now],
      ticklerTypeId: "All",
    };
  }, []);

  const { isDisabled, onPrint } = usePrint(form, closeModal);

  const close = useCallback(() => closeModal(false), [closeModal]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className="pt-6 processing-print-form"
        initialValues={initialValues}
      >
        <Form.Item
          className="mb-4 w-100"
          label="Tickler Date Range"
          name="dates"
        >
          <RangePicker
            className="w-100"
            format={DATE_FORMAT}
            disabled={isDisabled}
          />
        </Form.Item>

        <Form.Item label="Type" name="ticklerTypeId" className="mb-4 w-100">
          <Select
            options={options}
            loading={isLoading}
            disabled={isLoading || isDisabled}
          />
        </Form.Item>
      </Form>

      <FormButtons
        isDisabled={isDisabled}
        onPrint={onPrint}
        closeModal={close}
      />
    </>
  );
};
