import { Button, Row } from "antd";
import { useCallback } from "react";

type TBtnsProps = {
  currentId: string | null;
  onSaveClick: (isNew: boolean, currentId: string | null) => Promise<void>;
  onSaveAndPrint: (currentId: string) => Promise<void>;
  onCancelClick: () => void;
  onDeleteClick: (id: string) => void;
  isFormDisabled: boolean;
};

export const FormButtons = ({
  currentId,
  onSaveClick,
  onSaveAndPrint,
  onCancelClick,
  onDeleteClick,
  isFormDisabled,
}: TBtnsProps) => {
  const onDelete = useCallback(
    () => onDeleteClick(currentId || ""),
    [currentId, onDeleteClick]
  );

  return (
    <Row className="default-form-buttons w-100 pa-6 divider d-flex justify-space-between position-absolute">
      <Row className="default-form-buttons-main">
        <Button
          shape="round"
          type="primary"
          disabled={isFormDisabled}
          loading={isFormDisabled}
          onClick={() => onSaveClick(false, currentId)}
        >
          {currentId !== null ? "Save" : "Add"}
        </Button>

        {currentId !== null && (
          <Button
            shape="round"
            disabled={isFormDisabled}
            loading={isFormDisabled}
            onClick={() => onSaveAndPrint(currentId)}
          >
            Save & Print
          </Button>
        )}

        {currentId === null && (
          <Button
            shape="round"
            disabled={isFormDisabled}
            loading={isFormDisabled}
            onClick={() => onSaveClick(true, currentId)}
          >
            Add & New
          </Button>
        )}
        <Button shape="round" disabled={isFormDisabled} onClick={onCancelClick}>
          Cancel
        </Button>
      </Row>
      <Row>
        {currentId !== null && (
          <Button type="link" disabled={isFormDisabled} onClick={onDelete}>
            Delete
          </Button>
        )}
      </Row>
    </Row>
  );
};
