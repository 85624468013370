import { useCallback } from "react";

export const useCalcAfter = () => {
  const calcAfter = useCallback((history: any, name: string) => {
    history.breakdown[`${name}After`] =
      (history.breakdown[`${name}Before`] || 0) -
      (history.breakdown[`${name}Amount`] || 0) +
      (history.breakdown[`${name}ToBalance`] || 0);
  }, []);

  const calcInterestAfter = useCallback((history: any) => {
    history.breakdown.interestAfter =
      (history.breakdown.interestBefore || 0) +
      (history.interestAmount || 0) -
      (history.breakdown.interestAmount || 0);
  }, []);

  const calcAfterBalance = useCallback(
    (history: any, toUpdate: { name: string; value: any } | undefined) => {
      if (toUpdate) {
        const namePath: string[] = toUpdate.name.split(".");

        if (namePath.length > 1) {
          if (!["lateCharge", "interest"].includes(namePath[0])) {
            calcAfter(history, namePath[0]);
          }

          if (namePath[0] === "interest" && namePath[1] !== "ToBalance") {
            calcInterestAfter(history);
          }
        }
      }
    },
    [calcAfter, calcInterestAfter]
  );

  return { calcAfterBalance, calcAfter, calcInterestAfter };
};
