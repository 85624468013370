import { FormInstance } from "antd";
import { useCallback, useState } from "react";

export const usePrint = (
  contractId: string,
  form: FormInstance,
  type: 0 | 1 | null
) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  const onPrint = useCallback(async () => {
    try {
      const values = await form.validateFields();

      setDisabled(true);

      const request = {
        ...values,
        contractId,
      };

      console.log(request);

      setDisabled(false);
    } catch {
      setDisabled(false);
    }
  }, [form, contractId]);

  return { onPrint, disabled };
};
