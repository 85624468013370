import { ReactComponent as IconDemandLetter } from "../../../../../../../../../images/icons/DemandLetter.svg";
import { ReactComponent as IconEscrowAgent } from "../../../../../../../../../images/icons/EscrowAgent.svg";
import { ReactComponent as IconImpound } from "../../../../../../../../../images/icons/Impound.svg";
import { ReactComponent as IconMortgage } from "../../../../../../../../../images/icons/Mortgage.svg";
import { ReactComponent as IconPassthru } from "../../../../../../../../../images/icons/Passthru.svg";
import { ReactComponent as IconPersonPrimary } from "../../../../../../../../../images/icons/PersonPrimary.svg";
import { ReactComponent as IconPersonSecondary } from "../../../../../../../../../images/icons/PersonSecondary.svg";
import { ReactComponent as IconReciept } from "../../../../../../../../../images/icons/Reciept.svg";
import { ReactComponent as IconServicer } from "../../../../../../../../../images/icons/Servicer.svg";
import { ReactComponent as IconImpoundDistribution } from "../../../../../../../../../images/icons/ImpoundDistribution.svg";
import { ReactComponent as IconAltPayee } from "../../../../../../../../../images/icons/AltPayee.svg";

const PAYEE_ICONS = [
  {
    value: 0,
    icon: (color: string) => <IconPersonPrimary fill={color} stroke={color} />,
  },
  {
    value: 1,
    icon: (color: string) => (
      <IconPersonSecondary fill={color} stroke={color} />
    ),
  },
  {
    value: 2,
    icon: (color: string) => <IconImpound fill={color} stroke={color} />,
  },
  {
    value: 5,
    icon: (color: string) => <IconMortgage fill={color} stroke={color} />,
  },
  {
    value: 6,
    icon: (color: string) => <IconPassthru fill={color} stroke={color} />,
  },
  {
    value: 7,
    icon: (color: string) => <IconPassthru fill={color} stroke={color} />,
  },
  {
    value: 8,
    icon: (color: string) => <IconDemandLetter fill={color} stroke={color} />,
  },
  {
    value: 9,
    icon: (color: string) => <IconEscrowAgent fill={color} stroke={color} />,
  },
  {
    value: 10,
    icon: (color: string) => <IconServicer fill={color} stroke={color} />,
  },
  {
    value: 11,
    icon: (color: string) => <IconReciept fill={color} stroke={color} />,
  },
  {
    value: 101,
    icon: (color: string) => (
      <IconImpoundDistribution fill={color} stroke={color} />
    ),
  },
  {
    value: 102,
    icon: (color: string) => <IconAltPayee fill={color} stroke={color} />,
  },
];

export const useGetPayeeIcon = (iconColor = "#2B59C3") => {
  const getIcon = (payeeType: { value: number; label: string } | undefined) => {
    if (payeeType) {
      const icon = PAYEE_ICONS.find(
        (icon) => icon.value === payeeType.value
      )?.icon;

      return <span className="mt-1">{icon ? icon(iconColor) : ""}</span>;
    }
  };

  return { getIcon };
};
