import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import {
  INTEREST_CALCULATION_METHODS,
  INTEREST_RATE_MAX_MSG,
} from "../../../../../../../../../lib/constants";
import { PaymmentSchedule } from "./PaymentSchedule";
import { useMemo } from "react";
import { Rule } from "antd/es/form";
import { useCalculateInterestPerDiem } from "../../../hooks/calculateInterestPerDiem";
import { FloatNumberInput } from "../../../../../../../../../components/FloatNumberInput";
import { usePassthruFormItem } from "../../../hooks/usePassthruFormItem";

type RightFormColProps = {
  form: FormInstance;
};

export const RightFormCol = ({ form }: RightFormColProps) => {
  const { calculate } = useCalculateInterestPerDiem(form);

  const { isPassthru } = usePassthruFormItem(form);

  const amountLabel: string = useMemo(
    () => (isPassthru ? "Passthru Amount" : "Principal and Interest Amount"),
    [isPassthru]
  );

  const amountName: string = useMemo(
    () => (isPassthru ? "regularAmountPassthru" : "principalAndInterestAmount"),
    [isPassthru]
  );

  const amountRules: Rule[] = useMemo(
    () => [
      {
        required: isPassthru,
        validateTrigger: "onSubmit",
      },
    ],
    [isPassthru]
  );

  const interestRateRule: Rule[] = useMemo(() => {
    return [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: string) {
          if (!value || +value <= 99) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(INTEREST_RATE_MAX_MSG));
        },
      }),
    ];
  }, []);

  return (
    <Col className="general-info-col ml-7">
      <PaymmentSchedule
        form={form}
        name="paymentSchedule"
        label="Payment Schedule"
      />
      <Form.Item
        label="	Interest Calculation Method"
        name={"interestCalculationMethod"}
        className="mb-4"
      >
        <Select options={INTEREST_CALCULATION_METHODS} onChange={calculate} />
      </Form.Item>

      <Row>
        <Col span={12}>
          <FloatNumberInput
            form={form}
            name="interestRate"
            className="pr-1 mb-4"
            label="Interest Rate"
            onChange={calculate}
            rules={interestRateRule}
            decimalLimit={4}
            prefix="%"
          />
        </Col>
        <Col span={12} className="pl-1">
          <Form.Item
            label="Interest Per Diem"
            name={"interestPerDiem"}
            className="disabled-input mb-4"
          >
            <Input prefix="$" disabled />
          </Form.Item>
        </Col>

        <FloatNumberInput
          form={form}
          name={amountName}
          className="disabled-input mb-4 w-100"
          label={amountLabel}
          includeThousandsSeparator
          rules={amountRules}
        />
      </Row>
    </Col>
  );
};
