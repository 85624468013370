import { FormInstance } from "antd";
import { PERCENT_LIMIT } from "../../../../../../../../../../lib/constants";
import { useEffect, useMemo, useRef, useState } from "react";
import { FloatNumberInput } from "../../../../../../../../../../components/FloatNumberInput";
import { PayeeInput } from "../PayeeInput";
import { Rule } from "antd/es/form";
import { TypeSelect } from "./TypeSelect";
import { AmountSwitcher } from "./AmountSwitcher";

type TPayeeFormProps = {
  form: FormInstance;
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  };
  onCustomerFound: (number: number | null, name: string[]) => void;
  hasPayeeId: any;
  hasAlternativePayeeId: any;
  isFormDisabled: boolean;
  isHistoryOpened?: boolean;
  isPayment?: boolean;
  isPassthru?: boolean;
};

export const MainPart = ({
  form,
  payeesNumber,
  onCustomerFound,
  hasPayeeId,
  hasAlternativePayeeId,
  isFormDisabled,
  isHistoryOpened = false,
  isPayment,
  isPassthru,
}: TPayeeFormProps) => {
  const [isPercentage, setIsPercentage] = useState<boolean>(
    form.getFieldValue("amountSwitcher") === 2
  );

  const [isRadioVisible, setIsRadioVisible] = useState<boolean>(
    !([5, 6, 7].includes(form.getFieldValue("type")) && !isPassthru)
  );

  const percentageRules: Rule[] = useMemo(
    () => [
      { required: true },
      {
        type: "number",
        transform: (value: any) => +value,
        max: 100,
        message: PERCENT_LIMIT,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  const amountRef = useRef<any>(null);

  useEffect(() => {
    if (isPayment) {
      const { current } = amountRef;

      if (current) {
        current.inputElement.focus();
        current.inputElement.select();
      }
    }
  }, [amountRef, isPayment]);

  return (
    <div className="pb-4">
      <TypeSelect
        form={form}
        isHistoryOpened={isHistoryOpened}
        setIsRadioVisible={setIsRadioVisible}
        setIsPercentage={setIsPercentage}
        isPassthru={isPassthru}
      />
      <PayeeInput
        form={form}
        label="Payee"
        name={["payeeId"]}
        onCustomerFound={onCustomerFound}
        hasId={hasPayeeId}
        payeesNumber={payeesNumber}
        isFormDisabled={isFormDisabled}
      />
      {!isHistoryOpened && (
        <PayeeInput
          form={form}
          label="Alternate Payee"
          name={["alternativePayeeId"]}
          onCustomerFound={onCustomerFound}
          hasId={hasAlternativePayeeId}
          payeesNumber={payeesNumber}
          isFormDisabled={isFormDisabled}
          isFieldRequired={false}
        />
      )}
      <div className="d-flex">
        {isPercentage ? (
          <FloatNumberInput
            name="percentage"
            label="Amount"
            prefix="%"
            decimalLimit={4}
            form={form}
            disabled={isFormDisabled}
            className="flex-grow-1"
            rules={percentageRules}
          />
        ) : (
          <FloatNumberInput
            name="amount"
            label="Amount"
            form={form}
            disabled={isFormDisabled}
            className="flex-grow-1"
            rules={[{ required: true }]}
            customRef={amountRef}
            includeThousandsSeparator
          />
        )}
        {!isPayment && isRadioVisible && (
          <AmountSwitcher
            isFormDisabled={isFormDisabled}
            setIsPercentage={setIsPercentage}
          />
        )}
      </div>
    </div>
  );
};
