import { useCallback } from "react";
import {
  editPaymentHistoryRecord,
  createPaymentHistoryRecord,
} from "../../../../../../../acions/accounts/paymentHistory";
import { FormInstance } from "antd";
import { useAppDispatch } from "../../../../../../../store";
import { useTransformAmounts } from "../../../../../../Payment/hooks/useTransformAmounts";

type TButtonHandlersArgs = {
  reloadData: () => void;
  setIsPaymentEdited: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance;
  instance: any;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  customerIds: any;
  setInitialForm: () => void;
  setOpenEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  isLCManualyChanged: boolean;
};

export const useButtonHandlers = ({
  reloadData,
  form,
  setIsPaymentEdited,
  instance,
  setIsDisabled,
  customerIds,
  setInitialForm,
  setOpenEditMode,
  isLCManualyChanged,
}: TButtonHandlersArgs) => {
  const dispatch = useAppDispatch();

  const { transform } = useTransformAmounts();

  const onCancelClick = useCallback(() => {
    setIsPaymentEdited(false);
    setInitialForm();
    setOpenEditMode(false);
  }, [setIsPaymentEdited, setInitialForm, setOpenEditMode]);

  const onSuccess = useCallback(() => {
    reloadData();
    setIsDisabled(false);
    setOpenEditMode(false);
    setIsPaymentEdited(false);
  }, [reloadData, setIsDisabled, setIsPaymentEdited, setOpenEditMode]);

  const edit = useCallback(
    async (id: string, req: any) => {
      try {
        await Promise.resolve(dispatch(editPaymentHistoryRecord(id, req)));

        onSuccess();
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, onSuccess, setIsDisabled]
  );

  const create = useCallback(
    async (req: any) => {
      try {
        await Promise.resolve(dispatch(createPaymentHistoryRecord(req)));

        onSuccess();
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, onSuccess, setIsDisabled]
  );

  const onSaveClick = useCallback(
    async (id: string | null, contractId: string) => {
      try {
        setIsDisabled(true);

        const data = await form.validateFields();

        const { dueDate, date, interestDateTo, breakdown } = data;

        const { lateChargeAfter, lateChargeBefore } = breakdown;

        const req = transform({
          ...data,
          payerId: customerIds?.payeeId,
          disbursements: instance?.disbursements,
          breakdown: {
            ...instance.breakdown,
            ...breakdown,
            lateChargeBefore: isLCManualyChanged
              ? lateChargeBefore || "0.00"
              : null,
            lateChargeAfter: isLCManualyChanged
              ? lateChargeAfter || "0.00"
              : null,
          },
          contractId,
          dueDate: dueDate.startOf("D").format(),
          date: date.startOf("D").format(),
          interestDateTo: interestDateTo
            ? interestDateTo.startOf("D").format()
            : undefined,
        });

        id ? edit(id, req) : create(req);
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [
      create,
      customerIds,
      edit,
      form,
      instance,
      setIsDisabled,
      transform,
      isLCManualyChanged,
    ]
  );

  return { onSaveClick, onCancelClick };
};
