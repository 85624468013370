import { Col, FormInstance, Row } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { AmountInput } from "./components/AmountInput";
import { ToBalanceInput } from "./components/ToBalanceInput";
import { useInputDisabled } from "./hooks/useInputDisabled";
import { BeforeAfterCell } from "./components/BeforeAfterCell";

type DataRowProps = {
  form: FormInstance;
  instance: any;
  label: string;
  name: string;
  toText: string;
  calculate: (toUpdate?: { name: string; value: any }) => void;
  isHistoryOpened: boolean;
  isPaymentPosted: boolean;
  isFormDisabled: boolean;
  openLCPopup?: () => void;
  openInterestPopup?: () => void;
};

export const DataRow = ({
  form,
  instance,
  label,
  name,
  toText,
  openLCPopup,
  openInterestPopup,
  calculate,
  isHistoryOpened,
  isPaymentPosted,
  isFormDisabled,
}: DataRowProps) => {
  const { getDisabled } = useInputDisabled(
    name,
    isHistoryOpened,
    isPaymentPosted,
    isFormDisabled
  );

  return (
    <Row className="w-100 py-2 align-center row">
      <Col span={5} className="px-2 d-flex align-center">
        <p className="my-0 text-md">{label}</p>
      </Col>
      <Col span={6} className="px-2">
        <AmountInput
          form={form}
          name={name}
          calculate={calculate}
          getDisabled={getDisabled}
        />
      </Col>
      <Col span={8} className="px-2">
        <Row className="align-center">
          <ToBalanceInput
            form={form}
            name={name}
            calculate={calculate}
            getDisabled={getDisabled}
          />
          <Row className="d-block pl-1">
            <p className="my-0 text-sm title">to</p>
            <p className="my-0 text-sm title">{toText}</p>
          </Row>
        </Row>
      </Col>
      <Col span={5} className="px-2">
        <Row className="align-center justify-end">
          {name === "lateCharge" && (
            <SettingOutlined className="logo-cursor" onClick={openLCPopup} />
          )}
          {instance && (
            <BeforeAfterCell
              name={name}
              instance={instance}
              isPaymentPosted={isPaymentPosted}
              isHistoryOpened={isHistoryOpened}
              openInterestPopup={openInterestPopup}
            />
          )}
        </Row>
      </Col>
    </Row>
  );
};
