import { MenuProps, Row } from "antd";
import { useCallback, useMemo, useState, MouseEvent } from "react";

export const usePrintOptions = () => {
  const [openTaxModal, setOpenTaxModal] = useState(false);

  const openTax = useCallback(() => setOpenTaxModal(true), []);

  const [histiryType, setHistoryType] = useState<0 | 1 | null>(null);

  const openHistoryModal = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const id: string = e.currentTarget.id;

    setHistoryType(id === "2" ? 0 : 1);
  }, []);

  const printOptions: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <Row>Full History</Row>,
      },
      {
        key: "2",
        label: (
          <Row id="2" onClick={openHistoryModal}>
            Payer History
          </Row>
        ),
      },
      {
        key: "3",
        label: (
          <Row id="3" onClick={openHistoryModal}>
            Payee History
          </Row>
        ),
      },
      {
        key: "4",
        label: <Row onClick={openTax}>Tax 1098</Row>,
      },
    ],
    [openHistoryModal, openTax]
  );

  return {
    openTaxModal,
    printOptions,
    histiryType,
    setOpenTaxModal,
    setHistoryType,
  };
};
