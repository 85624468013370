import { Col, Form, FormInstance, Row, Select } from "antd";
import { PAYMENT_LATE_CHARGES_ASSESS_METHOD } from "../../../../../../lib/constants";
import { InputRow } from "./InputRow";
import { useCallback, useMemo } from "react";
import { FloatNumberInput } from "../../../../../../components/FloatNumberInput";

type ConfigFormProps = {
  form: FormInstance;
  isHistoryOpened: boolean;
  calculate: (toUpdate?: {
    name: string;
    value: any;
    isUpdatedField?: boolean;
  }) => void;
};

export const ConfigForm = ({
  form,
  isHistoryOpened,
  calculate,
}: ConfigFormProps) => {
  const onAssessMethodChange = useCallback((): void => {
    calculate({
      name: "breakdown.lateCharge.assessMethod",
      value: true,
      isUpdatedField: true,
    });
  }, [calculate]);

  const unpaidMap = useMemo(
    () => [
      { name: "lateChargeBefore", label: "Unpaid Before" },
      { name: "lateChargeAfter", label: "Unpaid After" },
    ],
    []
  );

  const onUnpaidCalc = useCallback(
    (): void => calculate({ name: "unpaid", value: true }),
    [calculate]
  );

  return (
    <Row className="align-end w-100">
      <Col span={13}>
        <Row className="d-block">
          <p className="mt-0 mb-4 text-md w-500">
            Configuration for This Payment
          </p>
          <Row className="align-center mb-2 justify-space-between">
            <p className="my-0 text-md title">Assess Method</p>
            <Form.Item
              className="mb-0 late-charge-input"
              label=""
              name={["breakdown", "lateCharge", "assessMethod"]}
            >
              <Select
                options={PAYMENT_LATE_CHARGES_ASSESS_METHOD}
                onSelect={onAssessMethodChange}
              />
            </Form.Item>
          </Row>
          <InputRow
            form={form}
            label="Assessed"
            name={["breakdown", "lateCharge", "assessed"]}
            calculate={calculate}
          />
          <InputRow
            form={form}
            label="Paid"
            name={
              !isHistoryOpened
                ? ["breakdown", "lateChargeAmount"]
                : ["breakdown", "lateCharge", "paid"]
            }
            calculate={calculate}
            isUpdated
          />
          <InputRow
            form={form}
            label="Added to Unpaid"
            name={
              !isHistoryOpened
                ? ["breakdown", "lateChargeToBalance"]
                : ["breakdown", "lateCharge", "addedToUnpaid"]
            }
            calculate={calculate}
            isUpdated
          />
          <InputRow
            form={form}
            label="Added to Principal"
            name={
              !isHistoryOpened
                ? ["breakdown", "principalToBalance"]
                : ["breakdown", "lateCharge", "addedToPrincipal"]
            }
            calculate={calculate}
            isUpdated
          />
        </Row>
      </Col>
      <Col span={11}>
        <Row className="d-block ml-8 mb-2 py-2 px-3 unpaid-amounts-container">
          {unpaidMap.map((item: { name: string; label: string }, i: number) => (
            <Row
              key={i}
              className="justify-space-between align-center mb-1 w-100"
            >
              <span className="text-sm">{item.label}:</span>
              <FloatNumberInput
                name={["breakdown", item.name]}
                label=""
                form={form}
                className="unpaid-input mb-0"
                allowNegative
                includeThousandsSeparator
                onPressEnter={onUnpaidCalc}
              />
            </Row>
          ))}
        </Row>
      </Col>
    </Row>
  );
};
