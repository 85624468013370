import { ReactNode, useCallback, useState } from "react";
import { UsersService } from "../../../services/users";

export const useDeleteUser = (
  openDeleteModal: (id: string, title: string, subTitle?: ReactNode) => void
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);

        const hasAccess: boolean = await UsersService.hasUserPortalAccess(id);

        const subTitle: ReactNode = hasAccess ? (
          <p className="mb-0 mt-1">
            The user has portal access links, they will be automatically removed
          </p>
        ) : (
          <></>
        );

        openDeleteModal(id, "Delete User?", subTitle);

        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    },
    [openDeleteModal]
  );

  return { isLoading, onDelete };
};
