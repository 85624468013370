import { Tag, Dropdown, MenuProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { getFormatted } from "../../../lib/PhoneFormatter";
import { EllipsisOutlined } from "@ant-design/icons";
import { ReactNode, useCallback, useMemo, MouseEvent } from "react";
import { useGetFullName } from "../../../lib/hooks/getFullName";
import { useSidebarHeader } from "../../../lib/hooks/sidebarHeader";
import { useGetRole } from "../../../lib/hooks/getRole";
import { useGetUserRoleTagProps } from "../../../lib/hooks/getUserRoleTagProps";
import { useGetColumnSortValue } from "../../../lib/hooks/useGetColumnSortValue";
import { useDeleteUser } from "./useDeleteUser";

export const useGetTableColumns = (
  onOpenSideBar: (
    header: string | ReactNode,
    userId: string,
    isEdit?: boolean
  ) => void,
  onOpenBlockModal: (userId: string, isBlockValue: boolean) => void,
  openDeleteModal: (id: string, title: string) => void
) => {
  const { getFullName } = useGetFullName();

  const { isLoading, onDelete } = useDeleteUser(openDeleteModal);

  const { getColumnSortValue } = useGetColumnSortValue("userOrder");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [role, _, me] = useGetRole();

  const { getStatus, getHeader } = useSidebarHeader();

  const { getRoleTagProps } = useGetUserRoleTagProps();

  const onDeleteClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      const id: string = e.currentTarget.id;

      onDelete(id);
    },
    [onDelete]
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "firstName",
        render: (_: string, record: any) => {
          const header: ReactNode = getHeader(record);
          const fullName: string = getFullName(record);

          return (
            <div
              className="cell-elipsis"
              title={fullName}
              onClick={() => onOpenSideBar(header, record.id)}
            >
              <span className="link-btn">{fullName}</span>
            </div>
          );
        },
        sorter: true,
        sortOrder: (() => getColumnSortValue("0", "1"))(),
        width: "19%",
        ellipsis: true,
      },
      {
        title: "Username",
        dataIndex: "userName",
        render: (value: string) => {
          return (
            <div className="cell-elipsis" title={value || "-"}>
              <span>{value || "-"}</span>
            </div>
          );
        },
        width: "19%",
      },
      {
        title: "Email",
        dataIndex: "email",
        render: (value: string) => {
          return (
            <div className="cell-elipsis" title={value}>
              <span>{value}</span>
            </div>
          );
        },
        width: "19%",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        render: (value: string) => getFormatted(value).slice(2),
        width: "17%",
      },
      {
        title: "Role",
        dataIndex: "role",
        render: (value: string) =>
          value ? <Tag {...getRoleTagProps(value)}>{value}</Tag> : "-",
        sorter: true,
        sortOrder: (() => getColumnSortValue("2", "3"))(),
        width: "9%",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (value: number) => getStatus(value),
        sorter: true,
        sortOrder: (() => getColumnSortValue("4", "5"))(),
        width: "10%",
      },
      {
        title: "Action",
        render: (_: any, record: any) => {
          const header: ReactNode = getHeader(record);

          const { id, role: recordRole, status } = record;
          const { uid: myId } = me;

          const getBlockUnblockItem = (label: string, isBlocked: boolean) => {
            return {
              key: "3",
              label: (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpenBlockModal(id, isBlocked);
                  }}
                >
                  {label}
                </div>
              ),
            };
          };

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpenSideBar(header, id);
                  }}
                >
                  View Profile
                </div>
              ),
            },
          ];

          const editData = {
            key: "2",
            label: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenSideBar(header, id, true);
                }}
              >
                Edit Profile
              </div>
            ),
          };

          if (role === "Admin") {
            if (myId === id || recordRole === "User") {
              items.push(editData);
            }
          } else {
            items.push(editData);
          }

          if (status !== 3) {
            if (role === "Admin") {
              if (status === 2) {
                items.push(getBlockUnblockItem("Unblock Profile", false));
              } else if (recordRole === "User") {
                items.push(getBlockUnblockItem("Block Profile", true));
              }
            } else {
              myId !== id &&
                items.push(
                  getBlockUnblockItem(
                    `${status === 1 ? "Block" : "Unblock"} Profile`,
                    status === 1
                  )
                );
            }
          }

          if (recordRole === "User") {
            items.push({
              key: "4",
              label: (
                <div id={id} onClick={onDeleteClick}>
                  Delete User
                </div>
              ),
            });
          }

          return (
            <Dropdown menu={{ items }} trigger={["click"]} disabled={isLoading}>
              <EllipsisOutlined onClick={(e) => e.stopPropagation()} />
            </Dropdown>
          );
        },
        width: "7%",
      },
    ],
    [
      isLoading,
      getColumnSortValue,
      getFullName,
      getHeader,
      getRoleTagProps,
      getStatus,
      me,
      onOpenBlockModal,
      onOpenSideBar,
      role,
      onDeleteClick,
    ]
  );

  return { columns };
};
