import { Button, Form, FormInstance } from "antd";
import { CustomModal } from "../../../../lib/CustomModal";
import { useCallback, useState } from "react";
import { FormItems } from "./conponents/FormItems";

export const TicklersModal = () => {
  const [form]: FormInstance[] = Form.useForm();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = useCallback(() => setIsOpen(true), []);

  const closeModal = useCallback(
    (value: boolean) => {
      setIsOpen(value);
      form.resetFields();
    },
    [form]
  );

  return (
    <>
      <Button className="pa-0" type="link" size="small" onClick={openModal}>
        Ticklers
      </Button>
      <CustomModal
        isModalOpen={isOpen}
        setIsModalOpen={closeModal}
        width={300}
        titleText="Print Ticklers Report"
        headerClass="processing-print-modal--header"
        modalClass="processing-print-modal"
      >
        <FormItems form={form} closeModal={closeModal} />
      </CustomModal>
    </>
  );
};
