import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../store";
import { registerAdmin } from "../../../acions/users";

export const useCreateAdmin = (
  setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>,
  reloadData: () => void
) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    (values: any) => {
      const phone = `1${values.phone.replace(/[\s-()]/g, "")}`;

      setIsLoading(true);
      Promise.resolve(dispatch(registerAdmin({ ...values, phone })))
        .then(() => {
          setIsLoading(false);
          setShowSideBar(false);
          reloadData();
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [dispatch, reloadData, setShowSideBar]
  );

  return { isLoading, onSubmit };
};
