import { Button, Dropdown, MenuProps, Row } from "antd";
import { useCallback, useMemo, MouseEvent } from "react";
import { getFormattedCurrency } from "../../../../../../../../../../lib/currencyFormatter";
import { DownOutlined } from "@ant-design/icons";
import { usePrintActivity } from "./hooks/usePrintActivity";

type HeaderToolsProps = {
  contractId: string;
  balanceBeginningYear: number | null;
};

export const HeaderTools = ({
  contractId,
  balanceBeginningYear,
}: HeaderToolsProps) => {
  const balance: string = useMemo(() => {
    return balanceBeginningYear
      ? getFormattedCurrency(balanceBeginningYear)
      : "-";
  }, [balanceBeginningYear]);

  const { print, isLoading } = usePrintActivity(contractId);

  const onPrint = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      const id: any = +e.currentTarget.id;

      print(id === 3 ? undefined : id);
    },
    [print]
  );

  const items: MenuProps["items"] = useMemo(
    () =>
      ["Current Year", "Current & Last Year", "Last Year", "All History"].map(
        (item: string, i: number) => ({
          key: i,
          label: (
            <Row id={i.toString()} onClick={onPrint}>
              {item}
            </Row>
          ),
        })
      ),
    [onPrint]
  );

  return (
    <Row className="w-100 justify-space-between align-center pt-6 pb-4 px-6">
      <Dropdown menu={{ items }} trigger={["click"]}>
        <Button
          shape="round"
          className="d-inline-flex align-center"
          disabled={isLoading}
        >
          Print Activity
          <DownOutlined />
        </Button>
      </Dropdown>
      <Row>
        <p className="my-0 text-md">Balance Beginning of Year:&nbsp;</p>
        <p className="my-0 text-md w-500">{balance}</p>
      </Row>
    </Row>
  );
};
