import { Form, Row, Col, Input, Select, Button } from "antd";
import { useValidateOnBlur } from "../../../lib/hooks/validateOnBlur";
import { PhoneInput } from "../../../components/PhoneInput";
import { useEffect, useMemo } from "react";
import { useGetRole } from "../../../lib/hooks/getRole";
import { FullNameFormItems } from "./FullNameFormItems";
import { useGetEmailFormRules } from "../../../lib/hooks/getEmailFormRules";
import { useCreateAdmin } from "../hooks/useCreateAdmin";
import { IOption } from "../../../types/Option";

type CreateAdminProps = {
  setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  roles: IOption[];
  reloadData: () => void;
};

export const CreateAdmin = ({
  setShowSideBar,
  roles,
  reloadData,
}: CreateAdminProps) => {
  const [form] = Form.useForm();

  const [role] = useGetRole();

  const [validateOnBlur] = useValidateOnBlur(form);

  const { rules: emailRules } = useGetEmailFormRules();

  const { isLoading, onSubmit } = useCreateAdmin(setShowSideBar, reloadData);

  useEffect(() => {
    roles.length > 0 && form.setFieldValue("roleId", roles[0].value);
  }, [form, roles]);

  const formatedRoles: IOption[] = useMemo(() => {
    let value: IOption[] = [];
    if (role === "Admin") {
      const adminOption: IOption | undefined = roles.find(
        (item) => item.label === "Admin"
      );

      adminOption && value.push(adminOption);
    } else {
      value = roles.filter((item) => item.label !== "User");
    }
    return value;
  }, [roles, role]);

  return (
    <>
      <div className="px-6 pt-8 pb-6 sidebar-body">
        <Form
          autoComplete="off"
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          disabled={isLoading}
          className="user-management-form"
        >
          <FullNameFormItems form={form} />

          <Row className="align-center mb-4">
            <Col span={8}>
              <span className="text-md title">Email</span>
            </Col>
            <Col span={16}>
              <Form.Item name="email" className="mb-0" rules={emailRules}>
                <Input onBlur={(e) => validateOnBlur(e, "email")} />
              </Form.Item>
            </Col>
          </Row>

          <Row className="align-center mb-4">
            <Col span={8}>
              <span className="text-md title">Phone</span>
            </Col>
            <Col span={16}>
              <PhoneInput
                form={form}
                size="middle"
                formItemMargin="mb-0"
                disabled={isLoading}
              />
            </Col>
          </Row>

          <Row className="align-center mb-4">
            <Col span={8}>
              <span className="text-md title">Role</span>
            </Col>
            <Col span={16}>
              <div className="role-selector">
                <Form.Item name="roleId" className="mb-0">
                  <Select options={formatedRoles} defaultValue="Admin" />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="sidebar-footer px-6 py-6">
        <Button
          type="primary"
          shape="round"
          htmlType="submit"
          block
          onClick={form.submit}
          disabled={isLoading}
          loading={isLoading}
        >
          Create Admin
        </Button>
      </div>
    </>
  );
};
