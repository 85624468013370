import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import ReportService from "../../../../../../../../../../services/reportGeneration";
import { useDownloadReport } from "../../../../../../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../../../../../../lib/hooks/useOpenProgressModal";
import { useAppDispatch } from "../../../../../../../../../../store";
import { updateMailingKey } from "../../../../../../../../../../acions/actionsCreators/mailingKeyActions";

export const usePrint = (
  contractId: string,
  form: FormInstance,
  close: () => void
) => {
  const dispatch = useAppDispatch();

  const [disabled, setDisabled] = useState<boolean>(false);

  const { download } = useDownloadReport(setDisabled);

  const { openModal } = useOpenProgressModal(
    "Generating 1098 Report",
    "Generating 1098 Report failed",
    (id?: string) => {
      download(ReportService, id);

      dispatch(updateMailingKey());
    }
  );

  const onSave = useCallback(async () => {
    try {
      const values = await form.validateFields();

      if (!values.payerIds) {
        values.payerIds = [];
      }

      setDisabled(true);

      const id: string = await ReportService.generateReportByName(
        "Report1098/print",
        {
          ...values,
          contractId,
        }
      );

      openModal(id);

      close();
    } catch {
      setDisabled(false);
    }
  }, [form, openModal, close, contractId]);

  return { onSave, disabled };
};
