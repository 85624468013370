import { useCallback } from "react";

export const useCalcPrincipalToBalance = () => {
  const calcPrincipalToBalance = useCallback((history: any) => {
    if (
      history.breakdown.lateCharge.addedToPrincipal > 0 &&
      history.breakdown.principalToBalance <
        history.breakdown.lateCharge.addedToPrincipal
    ) {
      history.breakdown.principalToBalance =
        history.breakdown.lateCharge.addedToPrincipal;
    }
  }, []);

  return { calcPrincipalToBalance };
};
