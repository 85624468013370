import { Row } from "antd";
import { useMemo } from "react";
import { transformToFloat } from "../../../../../../../lib/transformToFloat";
import { EditOutlined } from "@ant-design/icons";
import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";

type BeforeAfterCellProps = {
  name: string;
  instance: any;
  isPaymentPosted: boolean;
  isHistoryOpened: boolean;
  openInterestPopup?: () => void;
};

export const BeforeAfterCell = ({
  name,
  instance,
  isPaymentPosted,
  isHistoryOpened,
  openInterestPopup,
}: BeforeAfterCellProps) => {
  const before: number = useMemo(
    () => transformToFloat(instance.breakdown[`${name}Before`]) || 0,
    [name, instance]
  );

  const after: number = useMemo(
    () => transformToFloat(instance.breakdown[`${name}After`]) || 0,
    [name, instance]
  );

  const isBeforeError: boolean = useMemo(
    () => (name === "lateCharge" ? !!before : before < 0),
    [before, name]
  );

  const isAfterError: boolean = useMemo(
    () => (name === "lateCharge" ? !!after : after < 0),
    [after, name]
  );

  return (
    <Row className="justify-end w-100">
      <Row className="flex-column align-end w-100">
        <p className={`my-0 text-sm ${isBeforeError && "text-color-red"}`}>
          {getFormattedCurrency(before)}
        </p>
        {name === "interest" && !isPaymentPosted && !isHistoryOpened ? (
          <Row
            className={`${name}-after w-100 justify-space-between`}
            onClick={openInterestPopup}
          >
            <EditOutlined className="edit-icon" />
            <p
              className={`my-0 text-sm after-editable elipsis ${
                isAfterError && "text-color-red"
              }`}
            >
              {getFormattedCurrency(after)}
            </p>
          </Row>
        ) : (
          <p
            className={`my-0 text-sm after-editable elipsis ${
              isAfterError && "text-color-red"
            }`}
          >
            {getFormattedCurrency(after)}
          </p>
        )}
      </Row>
    </Row>
  );
};
