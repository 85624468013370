import { Button, Card, Row, Table } from "antd";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { useCallback, useState } from "react";
import { useFetchDataCount } from "../../../../../../lib/hooks/useDefaultFetchDataCount";
import { TablePagination } from "../../../../../../components/TablePagination";
import { useDefaultFormSidebar } from "../../../../../../lib/hooks/defaultFormSidebar";
import { DocumentsForm } from "./components/DocumentsForm";
import { createPortal } from "react-dom";
import { useOnSortChange } from "./hooks/useOnSortChange";
import { useFetchData } from "../../../../../../lib/hooks/useDefaultFetchData";
import { TRequest } from "./types";
import { IDocumentExtended } from "../../../../../../types/Documents";
import DocumentsService from "../../../../../../services/accounts/documents";
import { useDefualtOnRow } from "../../../../../../lib/hooks/useDefaultOnRow";
import "./styles.scss";

type DocumentsProps = {
  accountId: string;
};

export const Documents = ({ accountId }: DocumentsProps) => {
  const [id, setId] = useState<string | null>(null);

  const {
    request,
    isLoading: isCountLoading,
    total,
    onPageChange,
    fetchCount,
    setRequest,
  } = useFetchDataCount<{ contractId: string; sortBy?: number }>(
    DocumentsService,
    { contractId: accountId, sortBy: 2 }
  );

  const { data, isLoading, fetchData } = useFetchData<
    TRequest,
    IDocumentExtended
  >(DocumentsService, request);

  const reloadData = useCallback((): void => {
    fetchCount();
    fetchData();
  }, [fetchData, fetchCount]);

  const { formWrapper, isOpen, setIsOpen } = useDefaultFormSidebar({
    id,
    setId,
    reloadData,
    title: "Document",
    parentId: accountId,
    FormElem: DocumentsForm,
  });

  const openDocument = useCallback(
    (id: string) => {
      setIsOpen(true);
      setId(id);
    },
    [setIsOpen]
  );

  const { onRow } = useDefualtOnRow(openDocument);

  const { columns } = useGetTableColumns({
    reloadData,
    setId,
    setIsOpen,
    openDocument,
  });

  const { onSorterChange } = useOnSortChange(setRequest, request);

  const openSideBar = useCallback((): void => setIsOpen(true), [setIsOpen]);

  return (
    <Row className="h-100 w-100 px-8 py-6 card-table">
      <Card className="w-100 h-100">
        <Row className="w-100 d-flex justify-space-between">
          <h5 className="h h5 ant-table-title">Documents</h5>
          <Button shape="round" onClick={openSideBar}>
            Add New
          </Button>
        </Row>
        <Row className="w-100 pt-6 d-block card-table__body documents-wrapper">
          <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            loading={isLoading}
            rowKey="id"
            className="w-100 h-100 clickable-rows-table"
            onChange={onSorterChange}
            onRow={onRow}
          />
          {total > 20 && (
            <TablePagination
              isTotalLoading={isCountLoading}
              total={total}
              currentPage={request.pageNumber}
              pageSize={request.pageSize}
              onPageChange={onPageChange}
            />
          )}
        </Row>
      </Card>
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
