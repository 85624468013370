import { useCallback } from "react";
import { IPayeeDisbursementsBase } from "../../../../../../../../types/PayeeDisbursements";
import { calculateSum } from "../../../../../../../../lib/calculateSum";

export const useCalcTotals = () => {
  const calcTotalToDisburse = useCallback(
    (history: any, toUpdate: { name: string; value: any } | undefined) => {
      if (toUpdate) {
        if (["payerFee", "payeeFee", "disbursements"].includes(toUpdate.name)) {
          const { payeeFee, payerFee, disbursements } = history;

          const disbAmounts = disbursements
            .filter((item: any) => item.method !== 9)
            .map((disb: IPayeeDisbursementsBase) => disb.amount);

          const disbSum = calculateSum(disbAmounts);

          history.totalToDisburse = +payeeFee + +payerFee + disbSum;
        }

        if (toUpdate.name === "amount") {
          history.totalPayment = history.amount;
        }
      }
    },
    []
  );

  return { calcTotalToDisburse };
};
