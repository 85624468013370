import { ReactNode, useCallback, useMemo, useState } from "react";
import { SideBarContainer } from "../../../components/SideBarContainer";
import { useSidebarHeader } from "../../../lib/hooks/sidebarHeader";
import { UserContent } from "../SideBar/UserContent";
import { useOpenDataBySearchParam } from "../../../lib/hooks/openDataBySearchParam";

export const useOpenSideBar = (
  selectedUserId: string | null,
  roles: any[],
  setSelectedUserId: (value: string | null) => void,
  onOpenBlockModal: (userId: string | null, isBlockValue: boolean) => void,
  fetchUsers: () => void
) => {
  const [sideBarTitle, setSideBarTitle] = useState<string | ReactNode>("");

  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  const [isEditUser, setIsEditUser] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { getStatus: _, getHeader } = useSidebarHeader();

  const setHeaderTitleOnLoad = useCallback(
    (item: any) => {
      if (sideBarTitle === "" && item) {
        const value = getHeader(item);

        setSideBarTitle(value);
      }
    },
    [getHeader, sideBarTitle]
  );

  const onOpenSideBar = useCallback(
    (header: string | ReactNode, userId: string, isEdit?: boolean) => {
      isEdit && setIsEditUser(isEdit);
      setSelectedUserId(userId);
      setSideBarTitle(header);
      setShowSideBar(true);
    },
    [setSelectedUserId]
  );

  useOpenDataBySearchParam(onOpenSideBar, "userId");

  const onCloseSideBar = useCallback((value: boolean): void => {
    setShowSideBar(value);
    setIsEditUser(false);
  }, []);

  const userContentReloadData = useCallback((): void => {
    fetchUsers();
    setShowSideBar(false);
    setIsEditUser(false);
  }, [fetchUsers]);

  const sideBar: ReactNode = useMemo(
    () => (
      <SideBarContainer title={sideBarTitle} setShowSideBar={onCloseSideBar}>
        <UserContent
          userId={selectedUserId}
          onOpenBlockModal={onOpenBlockModal}
          setIsEditUser={setIsEditUser}
          isEditUser={isEditUser}
          roles={roles}
          reloadData={userContentReloadData}
          setHeaderTitleOnLoad={setHeaderTitleOnLoad}
        />
      </SideBarContainer>
    ),
    [
      sideBarTitle,
      onCloseSideBar,
      selectedUserId,
      onOpenBlockModal,
      isEditUser,
      roles,
      userContentReloadData,
      setHeaderTitleOnLoad,
    ]
  );

  return {
    showSideBar,
    sideBar,
    onOpenSideBar,
  };
};
