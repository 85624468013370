import { useCallback } from "react";
import { useAppDispatch } from "../../../../../../../store";
import { recalculateYTDAmounts } from "../../../../../../../acions/accounts/paymentHistory";

export const useRecalculate = (
  reloadData: () => void,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch();

  const onRecalculateClick = useCallback(
    async (id: string) => {
      try {
        setIsDisabled(true);

        await Promise.resolve(dispatch(recalculateYTDAmounts(id)));

        reloadData();
        setIsDisabled(false);
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, reloadData, setIsDisabled]
  );

  return { onRecalculateClick };
};
