import { useCallback } from "react";
import ReportService from "../../../../../services/reportGeneration";
import { useDownloadReport } from "../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../lib/hooks/useOpenProgressModal";
import { FormInstance } from "antd";
import { useGetDefaultRequest } from "../useGetDefaultRequest";

export const usePaymentsReport = (
  form: FormInstance,
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>,
  onCancelClick: () => void
) => {
  const { download } = useDownloadReport(setIsDownloading);

  const { openModal } = useOpenProgressModal(
    "Generating Payments Report",
    "Generating Payments Report failed",
    (id?: string) => download(ReportService, id)
  );

  const { getRequest } = useGetDefaultRequest();

  const printPaymentsReport = useCallback(async () => {
    try {
      const res = await form.validateFields();

      setIsDownloading(true);

      const request: any = getRequest(res);

      const id: string = await ReportService.generateReportByName(
        "PaymentsReport",
        request
      );

      openModal(id);

      onCancelClick();
    } catch {
      setIsDownloading(false);
    }
  }, [form, getRequest, onCancelClick, openModal, setIsDownloading]);

  return { printPaymentsReport };
};
