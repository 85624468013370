import { ColumnsType } from "antd/es/table";
import {
  DATE_FORMAT,
  ORANGE_1,
  PAYMENT_METHODS,
} from "../../../../../../../lib/constants";
import dayjs from "../../../../../../../configs/dayjs";
import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";
import {
  EyeOutlined,
  ClockCircleOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Dropdown, MenuProps, Tooltip } from "antd";
import { useCallback, useMemo } from "react";
import { useRecalculate } from "./useRecalculate";
import { useDelete } from "./useDelete";

type TTableColumnsProps = {
  setPaymentId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  reloadData: () => void;
  setOpenEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActionsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isActionsDisabled: boolean;
};

export const useGetTableColumns = ({
  setPaymentId,
  reloadData,
  setOpenEditMode,
  setIsActionsDisabled,
  isActionsDisabled,
}: TTableColumnsProps) => {
  const { onDeleteBtnClick } = useDelete(reloadData);

  const { onRecalculateClick } = useRecalculate(
    reloadData,
    setIsActionsDisabled
  );

  const onDeleteHistoryClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      onDeleteBtnClick(e.currentTarget.id);
    },
    [onDeleteBtnClick]
  );

  const onViewHistoryClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      setPaymentId(e.currentTarget.id);
    },
    [setPaymentId]
  );

  const onEditHistoryClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      setPaymentId(e.currentTarget.id);
      setOpenEditMode(true);
    },
    [setOpenEditMode, setPaymentId]
  );

  const onRecalculateYTDAmountsClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      onRecalculateClick(e.currentTarget.id);
    },
    [onRecalculateClick]
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: 100,
        fixed: "left",
        ellipsis: true,
        render: (value: string | Date, record: any) => {
          const { onHold } = record;

          return (
            <div className="">
              <span>{dayjs(value).format(DATE_FORMAT)}</span>
              {onHold && (
                <Tooltip
                  className="ml-2"
                  title="Release is held"
                  trigger={["hover"]}
                >
                  <ClockCircleOutlined style={{ color: ORANGE_1 }} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        width: 78,
        fixed: "left",
        ellipsis: true,
        render: (value: string | Date) => (
          <span>{value ? dayjs(value).format(DATE_FORMAT) : "-"}</span>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: 108,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Method",
        dataIndex: "method",
        key: "method",
        width: 92,
        ellipsis: true,
        render: (value: number) =>
          PAYMENT_METHODS.find((method) => method.value === value)?.label,
      },
      {
        title: "Principal Paid",
        dataIndex: "principalPaid",
        key: "principalPaid",
        width: 108,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Principal Balance",
        dataIndex: "principalBalance",
        key: "principalBalance",
        width: 96,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Interest Calculated",
        dataIndex: "interestCalculated",
        key: "interestCalculated",
        width: 108,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Interest Paid",
        dataIndex: "interestPaid",
        key: "interestPaid",
        width: 108,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Unpaid Interest Balance",
        dataIndex: "unpaidInterest",
        key: "unpaidInterest",
        width: 112,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "To Impound",
        dataIndex: "toImpound",
        key: "toImpound",
        width: 108,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "From Impound",
        dataIndex: "fromImpound",
        key: "fromImpound",
        width: 112,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Impound Balance",
        dataIndex: "impoundBalance",
        key: "impoundBalance",
        width: 112,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Mortgage/Passthru",
        dataIndex: "mortgage",
        key: "mortgage",
        width: 128,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Late Charges Calculated",
        dataIndex: "lateChargesCalculated",
        key: "lateChargesCalculated",
        width: 144,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Late Charges Paid",
        dataIndex: "lateChargesPaid",
        key: "lateChargesPaid",
        width: 136,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Late Charges to Principle",
        dataIndex: "lateChargeToPrinciple",
        key: "lateChargeToPrinciple",
        width: 160,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Unpaid Late Charge Balance",
        dataIndex: "unpaidLateChargeBalance",
        key: "unpaidLateChargeBalance",
        width: 142,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Payer Fees",
        dataIndex: "payerFees",
        key: "payerFees",
        width: 108,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Payee Fees",
        dataIndex: "payeeFees",
        key: "payeeFees",
        width: 108,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Principal Adjustment",
        dataIndex: "principalAdjustment",
        key: "principalAdjustment",
        width: 116,
        ellipsis: true,
        align: "right",
        render: (value: number) => (
          <div className="elipsis" title={`${value}`}>
            {getFormattedCurrency(value || 0)}
          </div>
        ),
      },
      {
        title: "Last Updated",
        dataIndex: "lastModified",
        key: "lastModified",
        width: 174,
        ellipsis: true,
        render: (value: string | Date, record: any) => {
          const { lastModifiedBy } = record;

          const lastUpdatedBy = lastModifiedBy ? ` by ${lastModifiedBy}` : "";

          return (
            <span>
              {dayjs(value).format(DATE_FORMAT)}
              {lastUpdatedBy}
            </span>
          );
        },
      },
      {
        key: "actions",
        width: 84,
        fixed: "right",
        render: (_: any, record: any) => {
          const { id } = record;

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <div id={id} onClick={onEditHistoryClick}>
                  Edit Payment History
                </div>
              ),
            },
            {
              key: "2",
              label: (
                <div id={id} onClick={onRecalculateYTDAmountsClick}>
                  Recalculate YTD Amounts
                </div>
              ),
            },
            {
              key: "3",
              label: (
                <div id={id} onClick={onDeleteHistoryClick}>
                  Delete Payment History
                </div>
              ),
            },
          ];

          return (
            <div className="d-flex justify-end ant-table-actions">
              <EyeOutlined
                className="ant-table-btn"
                id={id}
                onClick={onViewHistoryClick}
                disabled={isActionsDisabled}
              />
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                disabled={isActionsDisabled}
                overlayClassName="payment-hist-dropdown"
              >
                <EllipsisOutlined
                  className="ant-table-btn"
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [
      onEditHistoryClick,
      onRecalculateYTDAmountsClick,
      onDeleteHistoryClick,
      onViewHistoryClick,
      isActionsDisabled,
    ]
  );

  return { columns };
};
