import { Card, Col, Row } from "antd";
import { Categories } from "./components/Categories";
import { useCallback, useState } from "react";
import { TemplatesTable } from "./components/TemplatesTable";
import { TemplateCategoryItem } from "../../../types/Template";
import { useFetchData } from "./hooks/useFetchData";

export const TemplatesTab = () => {
  const [category, setCategory] = useState<TemplateCategoryItem | null>(null);

  const { isCatLoading, isTempLoading, data, fetchData } = useFetchData();

  const reloadTemplates = useCallback(() => fetchData(false), [fetchData]);

  return (
    <Row className="templates-card">
      <Row className="d-block w-100 py-6 pr-8 pl-2">
        <Card className="h-100">
          <Row className="h-100">
            <Col span={8} className="py-8 categories-list">
              <Categories
                data={data}
                isLoading={isCatLoading}
                category={category}
                setCategory={setCategory}
              />
            </Col>
            <Col span={16} className="py-8 h-100">
              <TemplatesTable
                category={category}
                isLoading={isTempLoading}
                fetchData={reloadTemplates}
              />
            </Col>
          </Row>
        </Card>
      </Row>
    </Row>
  );
};
