import { Form, FormInstance, Row } from "antd";
import { Loader } from "../../../../../HeaderActions/components/EmailsModal/components/Loader";
import { PayersList } from "../../PrintTaxModal/components/PayersList";
import { useFormattedOptions } from "../../PrintTaxModal/hooks/useFormattedOption";
import { useFetchListData } from "../hooks/useFetchListData";
import { PeriodItem } from "./PeriodItem";

type FormContentProps = {
  form: FormInstance;
  disabled: boolean;
  contractId: string;
  type: 0 | 1 | null;
};

export const FormContent = ({
  form,
  disabled,
  contractId,
  type,
}: FormContentProps) => {
  const { data, isLoading } = useFetchListData(contractId, type);

  const { options } = useFormattedOptions(data, "payerId");

  return (
    <Row className="d-block position-relative">
      {isLoading ? (
        <Row className="loader-container position-relative justify-center">
          <Loader />
        </Row>
      ) : (
        <Row>
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            disabled={disabled}
          >
            <PeriodItem />

            <PayersList options={options} />
          </Form>
        </Row>
      )}
    </Row>
  );
};
