import { DatePicker, Form } from "antd";
import { DATE_FORMAT } from "../../../../../../../../../../lib/constants";

const { RangePicker } = DatePicker;

export const PeriodItem = () => {
  return (
    <Form.Item label="Period" name="date" className="mb-6 period-picker">
      <RangePicker format={DATE_FORMAT} />
    </Form.Item>
  );
};
