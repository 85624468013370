import { useCallback } from "react";
import ReportService from "../../../../../../../services/reportGeneration";
import { setErrorMessage } from "../../../../../../../acions/actionsCreators/messageActions";
import { useAppDispatch } from "../../../../../../../store";
import { useDownloadReport } from "../../../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../../../lib/hooks/useOpenProgressModal";

export const useReceiptReport = (
  batchId: string,
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch();

  const { download } = useDownloadReport(setIsDownloading);

  const onSuccess = useCallback(
    (id?: string) => download(ReportService, id),
    [download]
  );

  const { openModal } = useOpenProgressModal(
    "Generating Report",
    "Generating Report failed",
    onSuccess
  );

  const print = useCallback(
    async (toEscrow: boolean) => {
      try {
        setIsDownloading(true);

        const id: string = await ReportService.generateReceiptReportByName(
          toEscrow ? "DepositReceiptToEscrowAccount" : "DepositReceipt",
          batchId
        );

        openModal(id);
      } catch (err: any) {
        dispatch(setErrorMessage(err.response.data.title, true));

        setIsDownloading(false);
      }
    },
    [setIsDownloading, batchId, dispatch, openModal]
  );

  return { print };
};
