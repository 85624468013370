import { Col, Form, FormInstance, Row, Select } from "antd";
import { useMemo } from "react";
import { useFetchTemplates } from "../../../../pages/Account/components/View/Admin/HeaderActions/components/EmailsModal/components/SubjectRow/hooks/useFetchTemplates";
import { useGenerateAttachment } from "./hooks/useGenerateAttachment";

type TemplateSelectProps = {
  form: FormInstance;
  contractId: string;
  isLoading: boolean;
  disabled: boolean;
  setGlobalIsLoading: (value: boolean) => void;
};

export const TemplateSelect = ({
  form,
  contractId,
  isLoading,
  disabled,
  setGlobalIsLoading,
}: TemplateSelectProps) => {
  const { isLoading: isTemplatesLoading, formatted } = useFetchTemplates();

  const isDisabled: boolean = useMemo(
    () => isTemplatesLoading || isLoading || disabled,
    [disabled, isLoading, isTemplatesLoading]
  );

  useGenerateAttachment(form, contractId, setGlobalIsLoading);

  return (
    <Row>
      <Col span={9}>
        <Form.Item className="mb-4" label="Letter" name="templateId">
          <Select
            options={formatted}
            disabled={isDisabled}
            placeholder="Select from Templates"
            labelInValue
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
