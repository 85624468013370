import { Button, Col, Row } from "antd";
import { useGetTypeIcon } from "../../Processing/hooks/useGetTypeIcon";
import { ReactNode, useCallback, useMemo } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useGetStatusLabel } from "../../Batch/components/BatchDetails/components/PaymentsTable/hooks/useGetStatusLabel";
import { PostPaymentButtons } from "./PostPaymentButtons";
import { useIsTransfer } from "../hooks/useIsTransfer";
import { useUnpost } from "../hooks/useUnpost";
import {
  NavigateFunction,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ROUTES from "../../../configs/routesConfig";

type HeaderProps = {
  paymentId: string;
  isFormBlured: boolean;
  isLoading: boolean;
  instance: any;
  save: (action: string, onSaved: () => void) => void;
  post: (id: string) => void;
  backToNew: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Header = ({
  paymentId,
  isFormBlured,
  isLoading,
  instance,
  save,
  post,
  backToNew,
  setIsLoading,
}: HeaderProps) => {
  const navigate: NavigateFunction = useNavigate();

  const isCreate: boolean = useMemo(() => paymentId === "create", [paymentId]);

  const { getIcon } = useGetTypeIcon();

  const { batchId } = useParams();

  const [searchParams] = useSearchParams();

  const { getStatusLabel } = useGetStatusLabel();

  const { isTransfer } = useIsTransfer(instance);

  const back = useCallback((): void => {
    const location: any =
      window.history.length === 1 ? `${ROUTES.PROCESSING}/${batchId}` : -1;
    navigate(location);
  }, [batchId, navigate]);

  const { openPopup } = useUnpost(back, setIsLoading);

  const onUnpost = useCallback(() => {
    openPopup(paymentId);
  }, [paymentId, openPopup]);

  const name: string = useMemo(() => {
    const batchNumber = searchParams.get("batchNumber");
    const type = searchParams.get("type");

    if (batchNumber && type) {
      return `${getIcon(+type).name}: ${batchNumber}`;
    }

    return "";
  }, [getIcon, searchParams]);

  const nameSection: ReactNode = useMemo(() => {
    if (isCreate) {
      return (
        <Col className="d-flex align-center">
          <h3 className="h h3 mr-8">Create Payment</h3>
          <h3 className="h h5 pl-8 header-name">{name}</h3>
        </Col>
      );
    } else {
      return (
        <>
          {instance ? (
            <Col className="d-flex align-center">
              <Button
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size="large"
                className="back-btn mr-6"
                onClick={back}
              />
              <h3 className="h h3 mr-8">Payment: {instance.paymentNumber}</h3>
              {getStatusLabel(instance.isPosted)}
              <h3 className="h h5 ml-6 pl-8 header-name">{name}</h3>
            </Col>
          ) : (
            <Row />
          )}
        </>
      );
    }
  }, [isCreate, name, instance, back, getStatusLabel]);

  const isActionBtnDisabled: boolean = useMemo(
    () => isFormBlured || isLoading,
    [isFormBlured, isLoading]
  );

  const isPosted: boolean = useMemo(() => {
    return paymentId && instance?.isPosted;
  }, [instance, paymentId]);

  const showTransferPayment: boolean = useMemo(() => {
    return !isCreate ? !instance?.isPosted && isTransfer : isTransfer;
  }, [instance?.isPosted, isCreate, isTransfer]);

  return (
    <Row className="px-8 py-5 align-center title justify-space-between header-container">
      {nameSection}
      <Col className="d-flex align-center">
        {showTransferPayment && (
          <Row className="mr-4 flex-column align-end">
            <p className="text-md my-0">Transfer Payment</p>
            <span className="text-sm title">
              Payments to other accounts will be created as drafts
            </span>
          </Row>
        )}
        <PostPaymentButtons
          paymentId={paymentId}
          isPosted={isPosted}
          isActionBtnDisabled={isActionBtnDisabled}
          save={save}
          post={post}
        />
        {isCreate && (
          <>
            {!isTransfer && (
              <Button
                className="mr-4"
                shape="round"
                disabled={isActionBtnDisabled}
                onClick={() => save("post", backToNew)}
              >
                Post & New
              </Button>
            )}
            <Button shape="round" onClick={back} disabled={isLoading}>
              Cancel
            </Button>
          </>
        )}
        {isPosted && (
          <Button shape="round" disabled={isLoading} onClick={onUnpost}>
            Unpost
          </Button>
        )}
      </Col>
    </Row>
  );
};
