import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import ReportService from "../../../../../services/reportGeneration";
import { useDownloadReport } from "../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../lib/hooks/useOpenProgressModal";

export const usePrint = (
  form: FormInstance,
  closeModal: (value: boolean) => void
) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { download } = useDownloadReport(setIsDisabled);

  const { openModal } = useOpenProgressModal(
    "Generating Ticklers Report",
    "Generating Ticklers Report failed",
    (id?: string) => download(ReportService, id)
  );

  const onPrint = useCallback(async () => {
    try {
      setIsDisabled(true);

      const values = await form.validateFields();

      const request: any = {
        ticklerDateFrom: values.dates[0].format(),
        ticklerDateTo: values.dates[1].format(),
      };

      if (values.ticklerTypeId !== "All") {
        request.ticklerTypeId = values.ticklerTypeId;
      }

      const id: string = await ReportService.generateReportByName(
        "TicklersReport",
        request
      );

      openModal(id);

      closeModal(false);
    } catch {
      setIsDisabled(false);
    }
  }, [form, openModal, closeModal]);

  return { isDisabled, onPrint };
};
