import { Col, FormInstance, Row } from "antd";
import { FloatNumberInput } from "../../../../../components/FloatNumberInput";
import { useCallback, useMemo, useState } from "react";
import { DataRow } from "./DataRow";
import { LateChargesModal } from "./LateChargeModal";
import { InterestAfterModal } from "./InterestAfterModal";
import { PaymentManualUpdated } from "../../../../../types/PaymentManualUpdated";
import { Header } from "./common/Header";

type BreakDownProps = {
  form: FormInstance;
  instance: any;
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  calculate: () => void;
  isHistoryOpened: boolean;
  setManualUpdated?: React.Dispatch<React.SetStateAction<PaymentManualUpdated>>;
};

export const BreakDown = ({
  form,
  instance,
  isFormDisabled,
  isPaymentPosted,
  calculate,
  isHistoryOpened,
  setManualUpdated,
}: BreakDownProps) => {
  const [isLCModalOpen, setIsLCModalOpen] = useState<boolean>(false);

  const [isInterestModalOpen, setIsInterestModalOpen] =
    useState<boolean>(false);

  const openLCPopup = useCallback(() => {
    setManualUpdated &&
      setManualUpdated((prev) => ({ ...prev, lateCharge: true }));
    setIsLCModalOpen(true);
  }, [setManualUpdated]);

  const openInterestPopup = useCallback(() => setIsInterestModalOpen(true), []);

  const contractId: string = useMemo(
    () => (instance ? instance.contractId : ""),
    [instance]
  );

  const interestAfter: string = useMemo(
    () => (instance ? instance.breakdown.interestAfter : ""),
    [instance]
  );

  const rowsData = useMemo(
    () => [
      { label: "Principal", name: "principal", toText: "Principal" },
      { label: "Interest", name: "interest", toText: "Unpaid" },
      { label: "Late Charge", name: "lateCharge", toText: "Unpaid" },
      { label: "From Impound", name: "fromImpound", toText: "Impound" },
    ],
    []
  );

  return (
    <Row className="d-block breakdown__container">
      <Header instance={instance} />
      <Row className="d-block w-100">
        <Row className="w-100 py-2 header row">
          <Col span={5} className="px-2 bordered">
            <p className="my-0 text-md w-500">Type</p>
          </Col>
          <Col span={6} className="px-2 bordered">
            <p className="my-0 text-md w-500">Amount</p>
          </Col>
          <Col span={8} className="px-2 bordered">
            <p className="my-0 text-md w-500">To Balance</p>
          </Col>
          <Col span={5} className="px-2 d-flex justify-end">
            <p className="my-0 text-md w-500">Before/After</p>
          </Col>
        </Row>
        {rowsData.map((data, i: number) => {
          return (
            <DataRow
              key={i}
              form={form}
              instance={instance}
              label={data.label}
              name={data.name}
              toText={data.toText}
              openLCPopup={data.name === "lateCharge" ? openLCPopup : undefined}
              openInterestPopup={openInterestPopup}
              calculate={calculate}
              isHistoryOpened={isHistoryOpened}
              isPaymentPosted={isPaymentPosted}
              isFormDisabled={isFormDisabled}
            />
          );
        })}
        <Row className="w-100 align-center row passthru">
          <Col span={5} className="px-2">
            <p className="my-0 text-md">Mortgage/</p>
            <p className="my-0 text-md">Passthru</p>
          </Col>
          <Col span={6} className="px-2">
            <FloatNumberInput
              name={["breakdown", "mortgagePassthru"]}
              label=""
              form={form}
              className="totals-input mb-0"
              allowNegative
              onPressEnter={calculate}
              disabled={!isHistoryOpened || isPaymentPosted || isFormDisabled}
              includeThousandsSeparator
            />
          </Col>
        </Row>
      </Row>
      <LateChargesModal
        isOpen={isLCModalOpen}
        setIsOpen={setIsLCModalOpen}
        contractId={contractId}
        form={form}
        isFormDisabled={isFormDisabled}
        isPaymentPosted={isPaymentPosted}
        calculate={calculate}
        isHistoryOpened={isHistoryOpened}
      />
      <InterestAfterModal
        isOpen={isInterestModalOpen}
        setIsOpen={setIsInterestModalOpen}
        isPaymentPosted={isPaymentPosted}
        isFormDisabled={isFormDisabled}
        form={form}
        calculate={calculate}
        initialValue={interestAfter}
      />
    </Row>
  );
};
