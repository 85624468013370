import ROUTES from "../../../configs/routesConfig";
import { Row } from "antd";
import { NavigateFunction, Location } from "react-router-dom";
import { ReactNode, useMemo, MouseEvent } from "react";
import { NavItem } from "../../../types/NavItem";
import { Item } from "./Item";

type NavItemsProps = {
  navigate: NavigateFunction;
  location: Location;
  setNextLocation: (value: MouseEvent<HTMLAnchorElement>) => void;
};

export const NavItems = ({ location, setNextLocation }: NavItemsProps) => {
  const items: NavItem[] = useMemo(
    () => [
      {
        path: ROUTES.DASHBOARD,
        name: "Home",
      },
      {
        path: ROUTES.ACCOUNTS,
        name: "Accounts",
      },
      {
        path: ROUTES.CUSTOMERS,
        name: "Customers",
      },
      {
        path: ROUTES.USER_MANAGEMENT,
        name: "User Management",
      },
      {
        path: ROUTES.PROCESSING,
        name: "Processing",
      },
      {
        path: ROUTES.EMAILS,
        name: "Emails",
      },
      {
        path: ROUTES.REPORTS,
        name: "Reports",
      },
    ],
    []
  );

  return (
    <Row>
      {items.map(
        (item: NavItem, i: number): ReactNode => (
          <Item
            key={i}
            item={item}
            location={location}
            setNextLocation={setNextLocation}
          />
        )
      )}
    </Row>
  );
};
