import { Button, Dropdown, MenuProps, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useCallback, useMemo, useState } from "react";
import { LetterModal } from "../../../../../../../../components/LetterModal";
import { IPrimaryData } from "../../types/PrimaryData";
import { usePrintAccountInfo } from "./hooks/usePrintAccountInfo";
import { CouponsModal } from "./CouponsModal";
import { Dayjs } from "dayjs";

type PrintProps = {
  contractId: string;
  isLoading: boolean;
  primaryData: IPrimaryData;
  nextDueDate: Dayjs | null;
};

export const Print = ({
  contractId,
  isLoading,
  primaryData,
  nextDueDate,
}: PrintProps) => {
  const [letterId, setLetterId] = useState<string | null | undefined>(
    undefined
  );

  const [showCouponsModal, setShowCouponsModal] = useState<boolean>(false);

  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const { printAccountInfo } = usePrintAccountInfo(contractId, setIsPrinting);

  const openPrintLetter = useCallback(() => setLetterId(null), []);

  const openCoupons = useCallback(() => setShowCouponsModal(true), []);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: 1,
        label: (
          <Row id="1" onClick={printAccountInfo}>
            Account Info
          </Row>
        ),
      },
      {
        key: 2,
        label: (
          <Row id="2" onClick={openCoupons}>
            Coupons
          </Row>
        ),
      },
      {
        key: 3,
        label: (
          <Row id="3" onClick={openPrintLetter}>
            Letter from Template
          </Row>
        ),
      },
    ],
    [openPrintLetter, printAccountInfo, openCoupons]
  );

  const close = useCallback(() => setLetterId(undefined), []);

  return (
    <>
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        disabled={isLoading || isPrinting}
      >
        <Button shape="round" className="d-inline-flex align-center ml-4">
          Print <DownOutlined />
        </Button>
      </Dropdown>

      <LetterModal
        id={letterId}
        contractId={contractId}
        primaryData={primaryData}
        close={close}
        shouldOpen={letterId !== undefined}
      />

      <CouponsModal
        contractId={contractId}
        isOpen={showCouponsModal}
        setIsOpen={setShowCouponsModal}
        nextDueDate={nextDueDate}
      />
    </>
  );
};
