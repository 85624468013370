import { API_URL } from "../configs/apiUrl";
import authAxios, { authHeader } from "./data";

const generateEmailLetterReport = async (
  templateId: string,
  contractId: string
) => {
  const result = await authAxios.post(
    `${API_URL}ReportGeneration/EmailLetterReport/${templateId}`,
    { contractId },
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const generateReceiptReportByName = async (name: string, batchId: string) => {
  const result = await authAxios.post(
    `${API_URL}ReportGeneration/${name}/print`,
    null,
    {
      headers: authHeader(),
      params: { batchId },
    }
  );

  return result.data;
};

const generateReportByName = async (name: string, request: any) => {
  const result = await authAxios.post(
    `${API_URL}ReportGeneration/${name}`,
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const download = async (id: string) => {
  const response = await authAxios.get(
    `${API_URL}ReportGeneration/${id}/Download`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
  return response.data;
};

const ReportService = {
  generateEmailLetterReport,
  generateReceiptReportByName,
  generateReportByName,
  download,
};

export default ReportService;
