import { Form, FormInstance, Row, Tabs, TabsProps } from "antd";
import { useCallback, useMemo, useState } from "react";
import { Details } from "./Details";
import { PaymentHistory } from "./PaymentHistory";
import { Legal } from "./Legal";
import { Documents } from "./Documents";
import { Ticklers } from "./Ticklers";
import { Payoff } from "./Payoff";
import { useFetchAccount } from "./Details/hooks/fetchAccount";
import { useLeaveDetailsView } from "../../../hooks/leaveDetailsView";
import { useUpdateAccount } from "../../../hooks/updateAccount";
import { SearchHeader } from "../../SearchHeader";
import { EditPanel } from "./Details/components/EditPanel";
import { LeaveModal } from "../../../../../components/LeaveModal";
import dayjs from "../../../../../configs/dayjs";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../store";
import { setLocation } from "../../../../../acions/actionsCreators/nextLocation";
import { useHistoryBackStep } from "../../../../../lib/hooks/useHistoryBackStep";
import { useSetLastUpdated } from "./Details/hooks/setLastUpdated";
import ROUTES from "../../../../../configs/routesConfig";
import { Mailings } from "./Mailings";
import { transformToFloat } from "../../../../../lib/transformToFloat";
import { HeaderActions } from "./HeaderActions";
import { useSelector } from "react-redux";

type AdminViewProps = {
  contractId: string;
};

export const AdminView = ({ contractId }: AdminViewProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const mailingKey = useSelector((state: any) => state.mailingKey.key);

  const navigate: NavigateFunction = useNavigate();

  const { backLocation, reduceBackStep } = useHistoryBackStep(ROUTES.ACCOUNTS);

  const dispatch = useAppDispatch();

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [active, setActive] = useState<string>("1");

  const { data, isLoading, setData, fetchData, legalData } = useFetchAccount(
    contractId,
    form
  );

  const { lastUpdated } = useSetLastUpdated(data, null);

  const reloadAccount = useCallback((): void => {
    fetchData();
    setHasChanges(false);
  }, [fetchData]);

  const { onLeaveView, isFormDisabled, setIsFormDisabled } =
    useLeaveDetailsView(hasChanges);

  const {
    update,
    onCustomerFound,
    hasPrimaryBuyerId,
    hasPrimarySellerId,
    lcSectionKey,
  } = useUpdateAccount(form, contractId, setIsFormDisabled);

  const updateAndReset = useCallback(() => {
    update((): void => {
      const principalvalue = form.getFieldValue("principalAndInterestAmount");

      setData({
        ...data,
        ...form.getFieldsValue(),
        regularAmountPrincipalAndInterest: transformToFloat(principalvalue),
        lastModifiedDateTime: dayjs().format(),
      });
      setHasChanges(false);
    });
  }, [data, form, setData, update]);

  const onCancelChanges = useCallback((): void => {
    form.resetFields();
    form.setFields([
      {
        name: "bankAccountNumber",
        errors: [],
      },
    ]);
    setHasChanges(false);
  }, [form]);

  const onBackButtonClicked = useCallback((): void => {
    hasChanges && dispatch(setLocation(backLocation));

    navigate(backLocation);
  }, [backLocation, dispatch, hasChanges, navigate]);

  const onChangeTab = useCallback(
    (tabkey: string): void => {
      if (active !== "1") {
        setActive(tabkey);
      } else {
        const onSuccess = () => {
          setActive(tabkey);
          setHasChanges(false);
        };

        const onCancel = () => {
          onSuccess();
          onCancelChanges();
        };

        onLeaveView(() => update(onSuccess), onCancel);
      }
    },
    [active, onLeaveView, onCancelChanges, update]
  );

  const tabItems: TabsProps["items"] = useMemo(() => {
    return [
      {
        key: "1",
        label: "Account Details",
        children: (
          <Details
            id={contractId}
            form={form}
            data={data}
            isLoading={isLoading}
            isDisabled={isFormDisabled}
            setHasChanges={setHasChanges}
            onCustomerFound={onCustomerFound}
            hasPrimaryBuyerId={hasPrimaryBuyerId}
            hasPrimarySellerId={hasPrimarySellerId}
            reloadAccount={reloadAccount}
            lcSectionKey={lcSectionKey}
          />
        ),
      },
      {
        key: "4",
        label: "Payment History",
        children: (
          <PaymentHistory
            contractId={contractId}
            accountNumber={data ? data.accountNumber : 0}
            key={`PaymentHistory${contractId}`}
            reloadAccount={reloadAccount}
          />
        ),
      },
      {
        key: "6",
        label: "Legal",
        children: (
          <Legal
            contractId={contractId}
            primaryBuyerId={hasPrimaryBuyerId}
            primarySellerId={hasPrimarySellerId}
            key={`Legal${contractId}`}
            active={active}
            data={legalData}
            isLoading={isLoading}
          />
        ),
      },
      {
        key: "2",
        label: "Documents",
        children: <Documents accountId={contractId} key={`Doc${contractId}`} />,
      },
      {
        key: "3",
        label: "Ticklers",
        children: (
          <Ticklers accountId={contractId} key={`Tickler${contractId}`} />
        ),
      },
      {
        key: "7",
        label: "Mailings",
        children: (
          <Mailings
            contractId={contractId}
            key={`Mailings${contractId}${mailingKey}`}
            mailing={data?.mailing}
            reloadAccount={reloadAccount}
          />
        ),
      },
      {
        key: "5",
        label: "Payoff Quotes",
        children: (
          <Payoff
            contractId={contractId}
            primaryBuyerId={hasPrimaryBuyerId}
            primarySellerId={hasPrimarySellerId}
            key={`PayoffQuotes${contractId}`}
          />
        ),
      },
    ];
  }, [
    active,
    contractId,
    data,
    form,
    hasPrimaryBuyerId,
    hasPrimarySellerId,
    isFormDisabled,
    isLoading,
    lcSectionKey,
    legalData,
    onCustomerFound,
    reloadAccount,
    mailingKey,
  ]);

  return (
    <div className="logged-in__page account-view__container">
      <Row className="py-5 px-8 align-center title justify-space-between header-container">
        <SearchHeader
          data={data}
          onBackButtonClicked={onBackButtonClicked}
          reduceBackStep={reduceBackStep}
        />
        <HeaderActions isLoading={isLoading} data={data} />
      </Row>
      <Row className="account-tabs">
        <Tabs
          activeKey={active}
          items={tabItems}
          onTabClick={onChangeTab}
          tabBarExtraContent={lastUpdated}
        />
      </Row>
      {hasChanges && active === "1" && (
        <>
          <EditPanel
            isDisabled={isFormDisabled}
            update={updateAndReset}
            className="edit-panel-container-top"
            onCancelChanges={onCancelChanges}
          />
          <LeaveModal hasChanges={hasChanges} onSave={update} />
        </>
      )}
    </div>
  );
};
