import { Form, Radio, RadioChangeEvent } from "antd";
import { useCallback, useMemo } from "react";
import { IOption } from "../../../../../../../../../../types/Option";

type AmountSwitcherProps = {
  isFormDisabled: boolean;
  setIsPercentage: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AmountSwitcher = ({
  isFormDisabled,
  setIsPercentage,
}: AmountSwitcherProps) => {
  const options: IOption[] = useMemo(
    () => [
      { label: "$", value: 1 },
      { label: "%", value: 2 },
    ],
    []
  );

  const onRadioChange = useCallback(
    (e: RadioChangeEvent) => setIsPercentage(e.target.value === 2),
    [setIsPercentage]
  );

  return (
    <Form.Item name="amountSwitcher">
      <Radio.Group
        options={options}
        optionType="button"
        className="ml-2 mt-6"
        onChange={onRadioChange}
        disabled={isFormDisabled}
      />
    </Form.Item>
  );
};
