import { Card, Row, Table } from "antd";
import { Filters } from "./Filters";
import { useCallback, useState } from "react";
import { useColumns } from "./hooks/useColumns";
import { useFetchCount } from "./hooks/fetchCount";
import { SimpleTablePagination } from "../../../../../components/SimpleTablePagination";
import { useFetchData } from "./hooks/useFetchData";

type PaymentsProps = {
  customerId: string;
};

export const Payments = ({ customerId }: PaymentsProps) => {
  const [request, setRequest] = useState<any>({
    pageNumber: 1,
    pageSize: 50,
  });

  const { data, isLoading } = useFetchData(customerId, request);

  const { columns } = useColumns();

  const { total, isLoading: isTotalLoading } = useFetchCount(
    customerId,
    request
  );

  const onPageChange = useCallback(
    (pageNumber: number, pageSize = 50) => {
      const newRequest = { ...request, pageNumber, pageSize };

      setRequest(newRequest);
    },
    [request]
  );

  return (
    <Row className="d-block py-6 px-8 h-100 card-table">
      <Card className="w-100 h-100">
        <Row className="w-100">
          <Filters
            customerId={customerId}
            isDataLoading={isLoading}
            request={request}
            setRequest={setRequest}
          />
        </Row>
        <Row className="w-100 pt-6 d-block card-table__body">
          <Table
            dataSource={data}
            columns={columns}
            loading={isLoading}
            pagination={false}
            rowKey="id"
            showSorterTooltip={false}
            className="w-100 h-100"
          />
          {total > 50 && (
            <SimpleTablePagination
              isLoading={isTotalLoading}
              total={total}
              pageNumber={request.pageNumber}
              onPageChange={onPageChange}
              pageSize={request.pageSize}
            />
          )}
        </Row>
      </Card>
    </Row>
  );
};
