import { FormInstance } from "antd";
import { IPayeeDisbursementsBase } from "../../../../../../types/PayeeDisbursements";
import { useAppDispatch } from "../../../../../../store";
import { setErrorMessage } from "../../../../../../acions/actionsCreators/messageActions";
import {
  PRIMARY_PAYEE_EXISTS,
  PRIMARY_PASSTHRU_EXISTS,
} from "../../../../../../lib/constants";
import { useCallback } from "react";
import { useTransformRequest } from "../../../../../Account/components/View/Admin/Details/components/PayeeDisbursements/hooks/useTransformRequest";

export const useSaveRecord = (
  disbursements: IPayeeDisbursementsBase[],
  reset: (value: IPayeeDisbursementsBase[]) => any
) => {
  const { transform } = useTransformRequest();

  const dispatch = useAppDispatch();

  const getCrossError = useCallback((type: number): string => {
    return `Can’t be added as there was added "Primary ${
      type === 0 ? "Passthru" : "Payee"
    }" Type.`;
  }, []);

  const showPrimaryError = useCallback(
    (type: number, addedType: number): boolean => {
      let errorMessage;

      if (addedType === type) {
        errorMessage =
          type === 0 ? PRIMARY_PAYEE_EXISTS : PRIMARY_PASSTHRU_EXISTS;
      } else {
        errorMessage = getCrossError(type);
      }

      dispatch(setErrorMessage(errorMessage, true));
      return true;
    },
    [dispatch, getCrossError]
  );

  const saveRecord = useCallback(
    async (
      form: FormInstance,
      customerIds: any,
      close: () => void,
      isNew: boolean,
      index: string | null,
      customerName?: string
    ) => {
      try {
        let res = await form.validateFields();

        let updatedDisbursementArr = [...disbursements];

        res = {
          ...res,
          ...customerIds,
          payeeName: customerName,
          payeeNumber: res.payeeId,
          type: res.type === "Impound Distribution" ? 101 : res.type,
          method: res.method === "Copy" ? 9 : res.method,
        };

        transform(res);

        const primaryIndex = updatedDisbursementArr.findIndex(
          (item: IPayeeDisbursementsBase) => [0, 7].includes(item.type)
        );

        let hasPrimaryError: boolean = false;

        if (!index) {
          if (primaryIndex > -1 && [0, 7].includes(res.type)) {
            hasPrimaryError = showPrimaryError(
              res.type,
              updatedDisbursementArr[primaryIndex].type
            );
          } else {
            updatedDisbursementArr.push(res);
          }
        } else {
          const itemIndex = updatedDisbursementArr.findIndex(
            (item: IPayeeDisbursementsBase) => item.index === index
          );

          if (itemIndex > -1) {
            if (
              primaryIndex > -1 &&
              [0, 7].includes(res.type) &&
              primaryIndex !== itemIndex
            ) {
              hasPrimaryError = showPrimaryError(
                res.type,
                updatedDisbursementArr[primaryIndex].type
              );
            } else {
              updatedDisbursementArr[itemIndex] = {
                ...updatedDisbursementArr[itemIndex],
                ...res,
              };
            }
          }
        }

        if (!hasPrimaryError) {
          await reset(updatedDisbursementArr);

          !isNew && close();
        }
      } catch {}
    },
    [disbursements, reset, showPrimaryError, transform]
  );

  return { saveRecord };
};
