import { useEffect, useState } from "react";
import dayjs from "dayjs";
import MailingsService from "../../../services/accounts/mailings";
import EmailsService from "../../../services/emails";
import { DATE_FORMAT, PDF_TYPE } from "../../../lib/constants";
import { FormInstance } from "antd";

export const useFetchData = (
  id: string | null | undefined,
  sourceType: 0 | 1 | 2,
  form?: FormInstance
) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!id);

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      const service = sourceType === 0 ? MailingsService : EmailsService;

      service
        .get(id, sourceType)
        .then((res: any) => {
          setIsLoading(false);

          const { email, date, attachments, receiverName, receiverType } = res;

          let receiverValue;

          if (receiverName) {
            receiverValue = receiverName;

            if (receiverType) {
              receiverValue = `${receiverValue} | ${
                receiverType === 1 ? "Buyer" : "Seller"
              }`;
            }
          } else {
            receiverValue = email;
          }

          const entity = {
            ...res,
            date: dayjs(date).format(DATE_FORMAT),
            fileList: attachments.map((file: any) => ({
              name: file.fileName,
              type: PDF_TYPE,
              status: "done",
              size: 1000,
              id: file.id,
            })),
          };

          if (receiverValue) {
            entity.email = [receiverValue];
          } else {
            delete entity.email;
          }

          form ? form.setFieldsValue(entity) : setData(entity);
        })
        .catch(() => setIsLoading(false));
    }
  }, [id, sourceType, form]);

  return { isLoading, data, setIsLoading };
};
