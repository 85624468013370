import { useCallback, useEffect, useState } from "react";
import PaymentHistoryService from "../../../../../../../services/accounts/paymentHistory";
import { TRecordHeaderData } from "../types";
import dayjs from "dayjs";
import { FormInstance } from "antd";

type TFetchRecordParms = {
  id: string | null;
  contractId: string;
  form: FormInstance;
};

export const useFetchRecord = ({ id, contractId, form }: TFetchRecordParms) => {
  const [isLoading, setIsLoading] = useState<boolean>(id !== undefined);

  const [initialData, setInitialData] = useState<any>(null);

  const [headerData, setHeaderData] = useState<TRecordHeaderData | null>(null);

  const onSuccess = useCallback(
    (res: any) => {
      const { disbursements } = res;

      const disbs = disbursements.map((item: any) => {
        const { bankAccountNumber, bankTransitRouting } = item;

        return {
          ...item,
          bankAccountNumber: bankAccountNumber || null,
          bankTransitRouting: bankTransitRouting || null,
        };
      });

      setInitialData({
        payment: {
          ...res,
          dueDate: res.dueDate ? dayjs(res.dueDate) : null,
          contractId: res.contractId || contractId,
          disbursements: disbs,
        },
        warnings: [],
      });
      setHeaderData({ ...res.batch, paymentNumber: res.paymentNumber });
      setIsLoading(false);

      form.setFieldValue(["payeeId"], res.payerNumber);
    },
    [contractId, form]
  );

  const fetch = useCallback(() => {
    setIsLoading(true);
    PaymentHistoryService.fetch(id)
      .then((res: any) => onSuccess(res))
      .catch(() => setIsLoading(false));
  }, [id, onSuccess]);

  const initialize = useCallback(() => {
    setIsLoading(true);
    PaymentHistoryService.initialize(contractId, dayjs().format())
      .then((res: any) => onSuccess(res))
      .catch(() => setIsLoading(false));
  }, [contractId, onSuccess]);

  useEffect(() => (id ? fetch() : initialize()), [fetch, id, initialize]);

  return { isLoading, initialData, setInitialData, headerData, setIsLoading };
};
