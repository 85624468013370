import { Tag, Tooltip } from "antd";
import { ReactNode, useCallback } from "react";

export const useTagRender = (
  optionsData: any,
  getTagLabel: (item: any, value: string) => string,
  getTooltip: (item: any, value: string) => string | ReactNode,
  getIsError: (item: any) => boolean
) => {
  const tagRender = useCallback(
    (props: any) => {
      const { value, closable, onClose } = props;

      const item = optionsData.find((item: any) => item.i.toString() === value);

      const isError: boolean = getIsError(item);

      const tooltipMsg = getTooltip(item, value);

      return (
        <Tag
          className={`mr-1 select-tag ${isError ? "error-label" : ""}`}
          closable={closable}
          onClose={onClose}
        >
          <Tooltip
            placement="top"
            title={tooltipMsg}
            overlayClassName="text-sm"
          >
            {getTagLabel(item, value)}
          </Tooltip>
        </Tag>
      );
    },
    [optionsData, getTagLabel, getTooltip, getIsError]
  );

  return { tagRender };
};
