import { Form, FormInstance, Modal } from "antd";
import { MODAL_MASK_COLOR } from "../../../../../../../../../lib/constants";
import { useCallback, useMemo } from "react";
import "../PrintTaxModal/styles.scss";
import { FormButtons } from "../PrintTaxModal/components/FormButtons";
import { FormContent } from "./components/FormContent";
import { usePrint } from "./hooks/usePrint";

type PrintHistoryModalPros = {
  contractId: string;
  historyType: 0 | 1 | null;
  setHistoryType: React.Dispatch<React.SetStateAction<0 | 1 | null>>;
};

export const PrintHistoryModal = ({
  contractId,
  historyType,
  setHistoryType,
}: PrintHistoryModalPros) => {
  const [form]: FormInstance[] = Form.useForm();

  const close = useCallback(() => {
    setHistoryType(null);
    form.resetFields();
  }, [setHistoryType, form]);

  const title: string = useMemo(
    () => `Print ${historyType === 0 ? "Payer" : "Payee"} History Report`,
    [historyType]
  );

  const { onPrint, disabled } = usePrint(contractId, form, historyType);

  return (
    <Modal
      open={historyType !== null}
      title={title}
      width={315}
      onCancel={close}
      centered
      maskStyle={{ backgroundColor: MODAL_MASK_COLOR }}
      wrapClassName="z-1000 position-fixed"
      className="bordered-modal print-tax-modal"
      footer={
        <FormButtons disabled={disabled} close={close} onSave={onPrint} />
      }
      destroyOnClose
    >
      <FormContent
        disabled={disabled}
        form={form}
        contractId={contractId}
        type={historyType}
      />
    </Modal>
  );
};
