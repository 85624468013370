import { useCallback } from "react";
import { useDownloadReport } from "../../../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../../../lib/hooks/useOpenProgressModal";
import ReportService from "../../../../../../../services/reportGeneration";
import { setErrorMessage } from "../../../../../../../acions/actionsCreators/messageActions";
import { useAppDispatch } from "../../../../../../../store";

export const useChecksReport = (
  batchId: string,
  reloadData: () => void,
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>,
  ids?: any[]
) => {
  const dispatch = useAppDispatch();

  const { download } = useDownloadReport(setIsDownloading);

  const onSuccess = useCallback(
    (id?: string) => {
      download(ReportService, id);
      reloadData();
    },
    [download, reloadData]
  );

  const { openModal } = useOpenProgressModal(
    "Generating Checks Report",
    "Generating Checks Report failed",
    onSuccess
  );

  const printChecksReport = useCallback(async () => {
    try {
      setIsDownloading(true);

      const request: any = { batchId };

      if (ids) {
        request.selectedDisbursementsIds = ids;
      }

      const id: string = await ReportService.generateReportByName(
        "ChecksReport",
        request
      );

      openModal(id);
    } catch (err: any) {
      dispatch(setErrorMessage(err.response.data.title, true));

      setIsDownloading(false);
    }
  }, [batchId, dispatch, ids, openModal, setIsDownloading]);

  return { printChecksReport };
};
