import { Form, FormInstance } from "antd";
import { useMemo } from "react";

export const usePassthruFormItem = (form: FormInstance) => {
  const paymentType: number = Form.useWatch("paymentType", { form });

  const isPassthru: boolean = useMemo(() => paymentType === 4, [paymentType]);

  return { isPassthru, paymentType };
};
